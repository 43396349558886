import React, { useState, useEffect, useContext } from 'react';
import { AuthContext, TitleRow } from 'components/lib';
import { Box, Grid, TextField, Button, Checkbox, IconButton, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Axios from 'axios';
import { Loader } from 'components/lib'; // Import the Loader component
import { PageIntro } from 'mbd-components/page-intro';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export function ColdStartFeed() {
    const [tabValue, setTabValue] = useState('items'); // 'items' or 'users'
    const [inputValue, setInputValue] = useState('');
    const [list, setList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]); // To track selected items
    const [loading, setLoading] = useState(false); // To track loading state

    const authContext = useContext(AuthContext);

    const considerations = [
        "Select a list of author IDs that creates quality content",
        "Select a list of topics (ai_labels) you would like to show a new user by default",
        "Include dynamic filters (such as user \"following\") to it is personalized to returning users",
        "Consider incluidng a good promotion feed to mix up content"
    ]

    // Fetch data when the component mounts or when tabValue changes (Items/Users toggle)
    useEffect(() => {
        setInputValue(''); // Clear input when tab changes
        fetchList(tabValue);
        setSelectedItems([]); // Reset selected items when switching between tabs
    }, [tabValue]);

    const fetchItemDetails = async (value) => {
        try {
            setLoading(true);
            const response = await Axios({
                url: '/api/farcaster/cast',
                method: 'get',
                params: { identifier: value, type: 'hash' },
            });

            const warpCastPostLink = response?.data?.data?.warpCastPostLink || null;

            setLoading(false);
            return warpCastPostLink || null;
        } catch (error) {
            console.error('Error fetching item details', error);
            setLoading(false);
            return null;
        }
    };

    const fetchUserDetailsByFid = async (value) => {
        try {
            setLoading(true);
            const response = await Axios({
                url: '/api/farcaster/user/bulk',
                method: 'get',
                params: { fids: value },
            });

            const username = response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data[0]?.username : null;
            const warpCastProfileLink = `https://warpcast.com/${username}`;

            setLoading(false);
            return warpCastProfileLink || null;
        } catch (error) {
            console.error('Error fetching user details', error);
            setLoading(false);
            return null;
        }
    };

    const fetchList = async (type) => {
        setList([]);
        setLoading(true);
        let responseListName = type === 'items' ? 'item_ids' : 'user_ids';
        try {
            // Fetch the latest data based on the current toggle (items or users)
            const response = await Axios({
                url: `/api/moderation/${type}`,
                method: 'get',
                params: { console_account_id: authContext?.user?.account_id || null },
            });

            // Check and set the list
            if (response?.data?.data && response?.data?.data?.[responseListName] && response?.data?.data?.[responseListName].length > 0) {
                setList(response?.data?.data?.[responseListName].map((val) => ({ id: val, value: val })));
            } else {
                setList([]); // Empty list if no data found
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching list', error);
            setLoading(false);
        }
    };

    const handleAddToExcludeList = async () => {
        if (!inputValue) return;

        try {
            setLoading(true); // Show loader
            // Update the list locally by adding the input value
            const updatedList = [...list, { id: inputValue, value: inputValue }];
            setList(updatedList);

            let data = {};
            let url = '';

            if (tabValue === 'items') {
                url = '/api/moderation/items/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    item_ids: updatedList.map(item => item.value),
                };
            } else {
                url = '/api/moderation/users/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    user_ids: updatedList.map(user => user.value),
                };
            }

            // Make API request to update the backend
            await Axios({
                url: url,
                method: 'post',
                data: data,
            });

            setInputValue(''); // Clear input after adding
            setLoading(false); // Hide loader
        } catch (error) {
            console.error('Error adding to exclude list', error);
            setLoading(false); // Hide loader on error
        }
    };

    const handleDeleteSelected = async (id) => {
        try {
            setLoading(true); // Show loader
            // Filter out the selected item locally
            const updatedList = list.filter(item => item.id !== id);
            setList(updatedList);

            let data = {};
            let url = '';

            if (tabValue === 'items') {
                url = '/api/moderation/items/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    item_ids: updatedList.map(item => item.value),
                };
            } else {
                url = '/api/moderation/users/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    user_ids: updatedList.map(user => user.value),
                };
            }

            // Make API request to update the backend
            await Axios({
                url: url,
                method: 'post',
                data: data,
            });

            setLoading(false); // Hide loader
        } catch (error) {
            console.error('Error deleting item', error);
            setLoading(false); // Hide loader on error
        }
    };

    const handleDeleteSelection = async () => {
        try {
            setLoading(true); // Show loader
            // Filter out the selected items locally
            const updatedList = list.filter(item => !selectedItems.includes(item.id));
            setList(updatedList);

            let data = {};
            let url = '';

            if (tabValue === 'items') {
                url = '/api/moderation/items/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    item_ids: updatedList.map(item => item.value),
                };
            } else {
                url = '/api/moderation/users/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    user_ids: updatedList.map(user => user.value),
                };
            }

            // Make API request to update the backend
            await Axios({
                url: url,
                method: 'post',
                data: data,
            });

            // Clear selected items after deletion
            setSelectedItems([]);
            setLoading(false); // Hide loader
        } catch (error) {
            console.error('Error deleting selected items', error);
            setLoading(false); // Hide loader on error
        }
    };

    const handleSelectItem = (id) => {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter(itemId => itemId !== id));
        } else {
            setSelectedItems([...selectedItems, id]);
        }
    };

    const handleDeleteAll = async () => {
        try {
            setLoading(true); // Show loader
            // Clear the list locally
            setList([]);

            let data = {};
            let url = '';

            if (tabValue === 'items') {
                url = '/api/moderation/items/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    item_ids: [],
                };
            } else {
                url = '/api/moderation/users/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    user_ids: [],
                };
            }

            // Make API request to update the backend
            await Axios({
                url: url,
                method: 'post',
                data: data,
            });

            setLoading(false); // Hide loader
        } catch (error) {
            console.error('Error deleting all items', error);
            setLoading(false); // Hide loader on error
        }
    };

    const handleView = async (value) => {
        let urlToOpen = null;
        if (tabValue === 'items') {
            urlToOpen = await fetchItemDetails(value);
        } else {
            urlToOpen = await fetchUserDetailsByFid(value);
        }
        
        if (urlToOpen) {
            window.open(urlToOpen, '_blank');
        } else {
            console.error('No URL to open');
        }
    };

    const handlePreview = async () => {
        let urlToOpen = null;
        if (tabValue === 'items') {
            urlToOpen = await fetchItemDetails(inputValue);
        } else {
            urlToOpen = await fetchUserDetailsByFid(inputValue);
        }

        if (urlToOpen) {
            window.open(urlToOpen, '_blank');
        } else {
            console.error('No URL to open');
        }
    };

    return (
        <Grid container justifyContent="center" style={{ minHeight: '100vh' }}>
            <Grid item xs={12} sm={10} md={8}>
                {loading ? (
                    <Loader /> 
                ) : (
                    <>
                        {/* Section for Exclude Items and Users header */}
                        {/* <Box sx={{ backgroundColor: '#fff', p: 5, borderRadius: 2, mb: 4 }}>
                            <Typography variant="h5" sx={{mb: 4}}>
                                Exclude Items and Users
                            </Typography>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                Use this page to manage the lists for excluding items and users for your organization. Items and users added on this page will apply to all feeds for your organization.
                            </Typography>
                        </Box> */}
                        <PageIntro 
                            title="Cold Start Feed" 
                            intro="Configure a feed to show when the user is new or is a returning user after a period of inactivity." />

                        <Box sx={{ backgroundColor: '#fff', p: 5, borderRadius: 2 }}>

                            <Typography variant="body2" color="textSecondary">
                                Select a feed
                            </Typography>
                            <Box sx={{ mt: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}>
                                <TextField
                                    label={tabValue === 'Feed ID'}
                                    variant="outlined"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    sx={{ flexGrow: 1 }}
                                />
                            {/* <FormControl fullWidth margin="dense" variant="outlined">
                                <Select value={feedId} onChange={handleFeedSelection} label="Select a feed"  sx={{borderWidth: 3, borderColor: '#43092b'}}>
                                    {visibility === 'private' && <MenuItem value="new">Create New Feed</MenuItem>}
                                    {(visibility === 'public' ? publicFeeds : privateFeeds).map((feed) => (
                                        <MenuItem key={feed.config_id} value={feed.config_id}>{feed.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                                <Button onClick={handlePreview} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 }, color: '#d00e83' }}>
                                    Preview Feed
                                </Button>
                                <Button
                                    onClick={handleAddToExcludeList}
                                    variant="contained"
                                    color="primary"
                                    sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 }, backgroundColor: '#d00e83' }}
                                >
                                    Save
                                </Button>
                            </Box>

                            <Typography variant="body1" mt={8} mb={6} sx={{fontWeight: 500}}>Considerations for cold start feed:</Typography>
                            {considerations.map((line, i) => 
                                <span key={`${line}-${i}`} style={{marginBottom:10, display: 'flex', flexDirection: 'row', alignContent: 'center'}}>
                                    <CheckCircleIcon/>                  
                                    <Typography variant='body1' color="textSecondary" gutterBottom style={{color: '#232323', fontSize: 13, fontWeight: 'lighter', minHeight: 40, marginLeft: 10}}>
                                        {line}
                                    </Typography>
                                </span>
                            )}
                        </Box>
                    </>
                )}
            </Grid>
        </Grid>
    );
}