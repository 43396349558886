import { Box, Typography } from '@mui/material';

export function PageIntro(props){

  return (
    <Box sx={{ backgroundColor: '#fff', p: 5, borderRadius: 2, mb: 4 }}>
      <Typography variant="h5" sx={{mb: 4}}>
          {props.title}
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
          {props.intro}
      </Typography>
    </Box>

  );
}