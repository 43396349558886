/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Card, Stat, ProgressBar, Chart, Table,
  Message, Grid, Animate, Feedback, useAPI } from 'components/lib';
import Axios from 'axios';
import { StreamlitIframe } from './streamlitIframe';
import {  apiKeyNames } from '../../helper/enum';


export function Playground(props){


 

const fetch = useAPI('/api/key');
const user = useAPI('/api/user');

const playgroundUrl = 'https://playground.mbd.xyz';
const playgroundName = user?.data?.name || '';
const playgroundEmail = user?.data?.email || '';
// const [keys, setKeys] = useState([]);
const [defaultKey, setDefaultKey] = useState(null);

async function revealDefault(data){
  // reveal the api key
  const key = (await Axios.get(`/api/key/${data.id}`))?.data?.data?.[0].key;
  setDefaultKey(key);

}

useEffect(() => {

  if (fetch?.data?.length){
    const keysData = fetch?.data
    for (const keyObject of keysData){
      if (keyObject.name === apiKeyNames.DEFAULT){
        revealDefault(keyObject);
        break;
      }
    }
  }
    
}, [fetch]);

const url  = `${playgroundUrl}/?api_key=${defaultKey}&name=${playgroundName}&email=${playgroundEmail}`

  return (
    <Animate type='pop'>

      <StreamlitIframe
        src =  {url}
        width="100%"
        height="2000px"
        title="Playground" 
      />

    </Animate>
  );
}
