/***
*
*   ONBOARDING
*   Example of onboarding flow*
*
**********/

import React, { Fragment, useContext, useEffect, useState, useCallback } from 'react';
import { AuthContext, Onboarding, Form, Message, useAPI } from 'components/lib';
import Axios from 'axios';
const _ = require('lodash');

let company_name = null, company_url = null, use_case = undefined, company_role = null, company_mail = null, telegram_handle = null;
let nextButtonUserInfo = false;
let saveButtonUserInfo = false;
let nextButtonCompanyInfo = false;
let saveButtonCompanyInfo = false;
let account_details, user;


export function OnboardingView(props){
  
  useEffect(() => {
    const fetchData = async () => {
      let res = (await Axios.get('/api/account', 'get'))?.data;
      // let user = useAPI('/api/user', 'get');
      account_details  = _.cloneDeep(res)
      res = (await Axios.get('/api/user', 'get'))?.data;

      user  = _.cloneDeep(res)
    };

    fetchData();
  }, []);

  company_name = account_details?.data?.company_name || null;
  company_url = account_details?.data?.company_url || null;
  use_case = account_details?.data?.use_case || undefined;
  company_role = user?.data?.company_role || null;
  company_mail = user?.data?.company_mail || null;
  telegram_handle = user?.data?.telegram_handle || null;

  if((company_name !== null && company_url !== null && use_case !== undefined) && (company_name !== '' && company_url !== '' && use_case !== '')){
    nextButtonCompanyInfo = true;
  }

  if((company_role !== null && telegram_handle !== null) && (company_role !== '' && telegram_handle !== '')){
    nextButtonUserInfo = true;
  }

  const context = useContext(AuthContext);
  const views = [
    {

      name: 'Getting Started',
      description: `Welcome, ${context.user.name}!`,
      component: <Welcome/>

    },
    {
          
      name: 'User Info',
      description: 'Tell us more about yourself',
      component: <UserInfo/>,
      nextButton: nextButtonUserInfo,
      saveButton: saveButtonUserInfo

    }
  ];

  if (context.permission.admin){
    views.push({
        
      name: 'Company Info',
      description: 'Tell us more about your company',
      component: <CompanyInfo/>,
      nextButton: nextButtonCompanyInfo,
      saveButton: saveButtonCompanyInfo

    });

    views.push({

      name: 'Invite your team', 
      description: `'mbd console is more fun when you invite your team.`,
      component: <InviteUsers/>,
      
    });
  }

  if (context.user.duplicate_user){
    views.unshift({

      name: 'Important!',
      description: '',
      component: <DuplicateUser/>

    })
  }

  return <Onboarding save onFinish='/dashboard' views={ views }/>

}

function DuplicateUser(){

  return (
    <Message
      type='warning'
      title={ `You already have an account` }
      text='We noticed you have already registered an account, so we used your original password to save you any confusion in the future.'
    />    
  )
}

function Welcome(){

  return (
    <Fragment>

      <p>Welcome to 'mbd Console. Unlock your AI SUPERPOWERS here! </p>
        
      <p><strong>Have fun playing with 'mbd Console! 🛠 </strong></p>

    </Fragment>
  )
}

function CompanyInfo(){

  

  useEffect(() => {
    const fetchData = async () => {
      let res = (await Axios.get('/api/account', 'get'))?.data;
      // let user = useAPI('/api/user', 'get');
      account_details  = _.cloneDeep(res)
    };

    fetchData();
    saveButtonUserInfo = false;
    saveButtonCompanyInfo = false;
  }, []);
  

  company_name = account_details?.data?.company_name || null;
  company_url = account_details?.data?.company_url || null;
  use_case = account_details?.data?.use_case || undefined;

  if((company_name !== null && company_url !== null && use_case !== undefined) && (company_name !== '' && company_url !== '' && use_case !== '')){
    nextButtonCompanyInfo = true;
  }
   
  return (
    <Form 
      inputs={{
        company_name: {
          label: 'Company Name',
          value: company_name,
          type: 'text',
          required: true,
          errorMessage: 'Please enter your company name here'
        },
        company_url: {
          label: 'Company URL',
          value: company_url,
          type: 'text',
          required: true,
          errorMessage: 'Please enter your company web url here'
        },
        use_case: {
          label: 'Use Case',
          value: use_case,
          type: 'textarea',
          required: true,
          errorMessage: 'Please enter your use case here'
        }

      }}
      buttonText='Save'
      url='/api/account/company_info'
      method='POST'
      callback={ res => {

        if (res.status === 200){
          nextButtonCompanyInfo = true;
          saveButtonCompanyInfo = true;

        }
 
       }}
    />
  )
}

function UserInfo(){

  useEffect(() => {
    const fetchData = async () => {
      let res = (await Axios.get('/api/user', 'get'))?.data;

      user  = _.cloneDeep(res)
    };

    fetchData();
    saveButtonUserInfo = false;
    saveButtonCompanyInfo = false;
  }, []);
  
  

  company_role = user?.data?.company_role || null;
  company_mail = user?.data?.company_mail || null;
  telegram_handle = user?.data?.telegram_handle || null;

  if((company_role !== null && telegram_handle !== null) && (company_role !== '' && telegram_handle !== '')){
    nextButtonUserInfo = true;
  }

  return (
    <Form 
      inputs={{
        company_role: {
          label: 'Role In the Company',
          value: company_role,
          type: 'text',
          required: true,
          errorMessage: 'Please enter your role in company here'
        },
        telegram_handle: {
          label: 'Telegram Handle',
          value:  telegram_handle,
          type: 'text',
          required: true,
          errorMessage: 'Please enter your telegram handle here'
        },
      }}
      buttonText='Save'
      url='/api/user/user_info'
      method='POST'
      callback={ res => {

       if (res.status === 200){
        nextButtonUserInfo = true;
        saveButtonUserInfo = true;
       }

      }}
    />
  )
}

function InviteUsers(props){

  useEffect(() => {
    saveButtonUserInfo = false;
    saveButtonCompanyInfo = false;
  }, []);
  

  return (
    <Form 
      inputs={{
        email: {
          label: 'Email',
          type: 'email',
          required: true,
        }
      }}
      buttonText='Send Invite'
      url='/api/invite'
      method='POST'
    />
  )
}