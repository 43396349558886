/***
*
*   HOME NAV
*   Navigation used on the main external website. Renders a dashboard link
*   if the user is signed in, or a sign up link if they are not
*
**********/

import { Fragment, useContext, useEffect } from 'react';
import { AuthContext, Logo, Link, Button, Content, ClassHelper, useNavigate } from 'components/lib';
import Style from './home.tailwind.js';

export function HomeNav(props){

  // console.log("HomeNav:",window.location.pathname);
  const urlPath = window.location.pathname;
  const queryParameters = new URLSearchParams(window.location.search)
  const login_to = queryParameters?.get("login_to") || null;
  // console.log("HomeNav:",login_to);
  const pattern = /\/signin/;
  const isSignin = pattern.test(urlPath);
  const isSignup = (urlPath === '/signup');
  const signedInPattern = /\/signup\/\w+/;

  // const navigate = useNavigate();

  // context
  const context = useContext(AuthContext);
  const user = JSON.parse(localStorage.getItem('user'));

  const css = ClassHelper(Style, {

    wrapper: true,
    color: !props.transparent,
    transparent: props.transparent

  });

  const NavButton = () => {
    if (isSignin) {
      return <Button small goto='/signup' text='Sign Up'  className='inline-block' /> 
    }
    else if (isSignup) {
      return <Button small goto='/signin' text='Sign In'  className='inline-block' />
    }
    else if (context.user?.token) {
      return <Button small action={context.signout} text='Sign Out'  className='inline-block' />
    }
    else {
      return <></>
    }
  }                

  return(
    <section className={ css }>
      <Content>  

        <Logo className={ Style.logo }/>
        <nav className={ Style.nav }>

          {/* <Link url='/pricing' text='Pricing' className={ Style.link } color='white'/> */}
          {/*  : */}

          { user?.token && login_to === 'readme'? 
              <Button small goto='/dashboard' text='Dashboard' className={ Style.button } />
            :
              <Fragment>
                <NavButton />
              {/* <Button small goto='/signup' text='Sign Up' className='inline-block' /> */}
            </Fragment>
          }
        </nav>
      </Content>
    </section>
  );
}