/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React, { useContext, useState, useEffect } from 'react';
import { Animate, AuthContext, ViewContext, Button, Form, Card, Link, Row, Message, SocialSignin, Loader, useLocation, useNavigate } from 'components/lib';
import { Typography } from '@mui/material';
import Axios from 'axios';

export function Signin(props){

  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const login_to = queryParams.get('login_to') || null;

  // state
  const [useMagic, setUseMagic] = useState(false);
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [loadingReadme, setLoadingReadme] = useState(false);
  const [form, setForm] = useState({

    email: {
      label: 'Email',
      type: 'email',
      required: true,
    },
    password: {
      label: 'Password',
      type: 'password',
      required: true,
    },
    forgotpassword: {
      type: 'link',
      url: '/forgotpassword',
      text: 'Forgot your password?'
    },
    login_to: {
      type: 'hidden',
      value: null
    }
  });

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {

    // was an error message passed from the server router?
    const qs = location.search;

    if (qs.includes('error')){
  
      const msg = decodeURIComponent(qs.substring(qs.indexOf('?error=')+7));
      viewContext.notification.show(msg, 'error');
  
    }
    
    const f = {...form };
    f.login_to.value = login_to;
    setForm(f);

  }, [location.search, viewContext.notification]);

  useEffect(() => {
    if (login_to === 'readme'){  
      if (user && user.token){
        loginToReadme();
      }
    }
    else {
      // redirect to appropriate page based on how far user onbonarded
      if (user?.token){
        if (user.verified){
          if (user.plan){
            if (user.onboarded){
              navigate('/dashboard');
            }
            else {
              navigate('/welcome');
            }
  
          }
          else {
            navigate('/signup/plan');
          }
        }
        else {
          navigate('/signup/verify');
        }
      } 
    }
  }, [user])

  const loginToReadme = async () => {
    try {  
      setLoadingReadme(true);
      const res = await Axios.get('/api/auth/readme');
      if (res.data?.readMeAuthUrl) {
        window.location.href = res.data.readMeAuthUrl;
      }
      return res;
    }
    catch (err){
      // fall back to login form if something went wrong here
      setLoadingReadme(false);
    }  
  }

  function toggleMagicLink(useMagic){

    const f = {...form };
    f.password.type = useMagic ? 'hidden' : 'password';
    f.password.required = useMagic ? false : true;
    f.forgotpassword.type = useMagic ? 'hidden' : 'link';

    setForm(f);
    setUseMagic(useMagic);

  }

  const signinCallback = (res) => {
    if (useMagic) {
      setMagicLinkSent(true);
    }
    else{
      if (res.data['2fa_required']) {
        if (login_to==='readme') {
          navigate(`/signin/otp?token=${res.data.token}&login_to=readme`);
        }
        else {
          navigate(`/signin/otp?token=${res.data.token}`);
        }
      }
      else {
        if (res.data.verified && login_to==='readme') {
          window.location.href = res.data.readMeAuthUrl;
        }
        else {
          navigate(authContext.signin(res));
        }
      }
    }
  }

  return(
    
      loadingReadme ? 
        <Card>
          <Card>
            <h2 style={{textAlign: 'center', color: '#7e8895', fontStyle: 'italic'}}>logging in to docs.mbd.xyz ...</h2>
          </Card>
          <Card>
            <Loader />
          </Card>
        </Card>
      :
        <Animate type='pop'>
          <Row title= "Sign in to 'mbd Console">

            { magicLinkSent ?
              <div style={{ width: '28em', margin: '0 auto' }}>
                <Message 
                  title='Check Your Email' 
                  type='success'
                  text='Please click the link in your email inbox to sign in.'
                /> 
              </div> :
              <Card restrictWidth center>

                {/* <SocialSignin network={['facebook', 'twitter']} useMagic={ useMagic } showOr /> */}

                {/* { useMagic ?
                  <Button 
                    text='Use password' 
                    color='blue' 
                    icon='shield' 
                    iconColor='white' 
                    className='mx-auto mb-5'
                    rounded iconButton small
                    action={ () => { toggleMagicLink(false) }}
                  /> :
                  <Button 
                    text='Use magic link instead' 
                    color='blue' 
                    icon='star' 
                    iconColor='white' 
                    className='mx-auto mb-5'
                    rounded iconButton small
                    action={ () => { toggleMagicLink(true) }}
                  />
                } */}

                <Form
                  inputs={ form }
                  method='POST'
                  url={ useMagic ? '/api/auth/magic' : '/api/auth' }
                  buttonText={ useMagic ? 'Send Magic Link' : 'Sign In' }
                  callback={signinCallback}
                />

                <div className='mt-4'>
                  Don't have an account? <Link url='/signup' text='Sign Up'/>
                </div>

              </Card>
            }
          </Row>
        </Animate>
  );
}
