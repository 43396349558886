import { Button } from '@mui/material';

export function PrimaryButton(props){

  const primaryColor = '#e01296';
  
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={props.onClick}
      sx={{ backgroundColor: primaryColor, '&:hover': { backgroundColor: '#ed9ed2' } }}
    >
        {props.children}
    </Button>
  );
}