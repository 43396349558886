/***
*
*   DASHBOARD
*
**********/

import {useState, React, useEffect, Fragment, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Stat, ProgressBar, Chart, Table,
  Message, Grid, Animate, Feedback, useAPI,Form, Button, Label, Content, GroupCardsContainer, Loader, AuthContext} from 'components/lib';
import Axios from 'axios';
import Style from './dashboard.tailwind.js';
import { apiKeyNames } from '../../helper/enum';
import { GetStarted, Header, SubHeader, Link } from './getStarted.js';
import { Card as MUICard, CardContent, Typography, Box, Avatar,  useMediaQuery, useTheme, Grid as MGrid } from '@mui/material';
import logo_image from './logo_image.png';
import {computeUnitsPerApiCall, mbdUsagePlans} from 'helper/constants'
import { Chart as ChartJS } from 'react-chartjs-2';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { FeedTemplateShowcase } from 'views/feed-config/feedTemplateShowcase.js';
const _ = require('lodash');

// TODO:  move this to common settings
const GRAPH_COLORS = [
  "#A5B8E0", 
  "#76C7F2", 
  "#80D6B7", 
  "#8CD790",
  "#5BC8C7", 
  "#A3D477", 
  "#F39C9F", 
  "#FFB785", 
  "#FFD67B", 
  "#FFF07C", 
  "#FFB07C", 
  "#FF9AA2", 
  "#FF99A8", 
  "#F8A5A8", 
  "#D6A3F9" 
] 

export function Dashboard(props){

  const subscription = useAPI('/api/account/subscription');
  const plans = useAPI('/api/account/plans');
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  let planStartTime = subscription?.data?.object?.current_period_start || null;
  let planEndTime = subscription?.data?.object?.current_period_end || null;
  const trialStart = subscription?.data?.object?.trial_start || null;
  const subscriptionInterval = subscription?.data?.object?.interval || null;
  const [frequency, setFrequency] = useState('day')
  const [period, setPeriod] = useState('1 month')
  const [perApiUsageChart, setPerApiUsageChart] = useState({loading: true});
  const [perApiComputeUsage, setPerApiComputeUsage] = useState([]);
  const [totalCopmuteUsed, setTotalComputeUsed] = useState(0);
  const [userComputeQuota, setUserComputeQuota] = useState(0);
  const [publicFeeds, setPublicFeeds] = useState([]); // State for public feeds
  const [privateFeeds, setPrivateFeeds] = useState([]); // State for private feeds

  const [perApiUsageChartColor, setPerApiUsageChartColor] = useState(perApiUsageChart?.data?.colors || []);
 
  const messageText = `This is your dashboard to get you started. Please read the documentation `;
  const [apiTilesData, setApiTilesData] = useState(perApiUsageChart?.data?.colors || {});
  const [appUrlList, setAppUrlList] = useState([{"value": "all","label": "All"}]);
  const [appNameList, setAppNameList] =  useState([{"value": "all","label": "All"}]);
  const [appUrl, setAppUrl] = useState('all');
  const [appName, setAppName] = useState('all');
  const [groupCardsData, setGroupCardsData] = useState({});
  const [feeLoader, setFeedLoader] = useState(false);

  const cache = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(cache);
  const planId = user?.plan || 'free';
  const planObject = plans?.data?.plans?.find(plan => plan.id === planId) || null;
  const plan = planObject?.name || 'BUILDR';
  let darkMode = user?.dark_mode || false;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



  // Fetch feeds based on visibility type
  const fetchFeeds = async (type) => {
    try {
      let result = await Axios({
        url: '/api/feed/configs',
        method: 'post',
        data: {
          visibility: type,
          console_account_id: authContext?.user?.account_id || null,
        },
      });
      return result?.data?.data || [];
    } catch (error) {
      console.error(`Error fetching ${type} feeds:`, error);
      // setSnackbarMessage(`Error fetching ${type} feeds`);
      // setShowSnackbar(true);
      return [];
    }
  };

  useEffect(() => {
    const fetchInitialFeeds = async () => {
        // Fetch feeds data directly
        const [publicFeedsData, privateFeedsData] = await Promise.all([
            fetchFeeds('public'),
            fetchFeeds('private'),
        ]);

        // Set the state for public and private feeds
        setPublicFeeds(publicFeedsData);
        setPrivateFeeds(privateFeedsData);
        setFeedLoader(false);
    };

    setFeedLoader(true);
    fetchInitialFeeds();
  }, []); // Add visibility as a dependency


  const handleFeedClick = (feed) => {
    navigate('/feed-builder', { state: { feedId: feed.config_id, mode: 'private' } });
  };

  
  const handleCreateNewFeedClick = () => {
    navigate('/feed-builder', { state: { mode: 'private', new: true } });
  };


  const  getNearestSameDayThisOrLastMonth = (timestamp) => {
    const givenDate = new Date(timestamp);
    const today = new Date();

    const day = givenDate.getDate();

    // Create dates for the same day in the current month and the previous month
    const thisMonth = new Date(today.getFullYear(), today.getMonth(), day);
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, day);

    // Check if the day is valid in both months (e.g., handling cases like 31st)
    const isThisMonthValid = thisMonth.getDate() === day;
    const isLastMonthValid = lastMonth.getDate() === day;

    // Determine the closest valid date
    let closestDate;
    if (isThisMonthValid && isLastMonthValid) {
        // Both dates are valid, choose the closest
        closestDate = Math.abs(thisMonth - today) < Math.abs(lastMonth - today) ? thisMonth : lastMonth;
    } else if (isThisMonthValid) {
        closestDate = thisMonth;
    } else if (isLastMonthValid) {
        closestDate = lastMonth;
    } else {
        // If neither date is valid, return an error or fallback (unlikely with standard months)
        throw new Error("The specified day is invalid for both this and last month.");
    }

    return closestDate;
}

  const isSameMonth = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
  }

  if(subscriptionInterval === 'year'){
    if(planStartTime && !isSameMonth(planStartTime, new Date().getTime())){
      planStartTime = getNearestSameDayThisOrLastMonth(planStartTime).getTime();
    }
    planEndTime = new Date().getTime();

  }

  if(trialStart && trialStart <= planStartTime && isSameMonth(trialStart, planStartTime)){
    planStartTime = trialStart;
  }
  

  useEffect(() => {
    const getPerAPIChartFilters  = async () => {
     

      let result = await Axios({

        url: '/api/dashboard/app-list-details',
        method: 'get',
        
      });
      setAppUrlList(result?.data?.data?.appUrlList || []);
      setAppNameList(result?.data?.data?.appNameList || []);
    };

    getPerAPIChartFilters();
    
    

  }, []);

  const getComputeUsagePieChartData = (apiData) => {

    let chartData = [];
    Object.keys(computeUnitsPerApiCall).forEach((key, i) => {
      let sum = 0;
      apiData.forEach((entry) => {
        if (entry.path.includes(key)) {
          sum += entry.total_consumed;
        }
      });
      sum *= computeUnitsPerApiCall[key];
      let datapoint = {
        id: i,
        value: sum, // Math.random() * (100000 - 50000) + 50000, // to test with random values
        label: `${key} (${sum} CU)`,
        color: GRAPH_COLORS[i]
      }
      chartData.push(datapoint);
    }
    );

    return chartData;
  };
 

  useEffect(() => {

    const getGraphData = async () => {
      setPerApiUsageChart({loading: true});
      let  result = await Axios({
        url: '/api/dashboard/api_usage_v2',
        method: 'get',
        params: {fromTimestamp: planStartTime, toTimestamp: planEndTime }
      });
      const consumptionData =  _.cloneDeep(result?.data?.data) || [];
      if(consumptionData && Array.isArray(consumptionData) && consumptionData.length){
        const groupedTilesData = {
          "casts-feed": {},
          "users-feed": {},
          "search": {},
          "labels": {}
        }

        for(const consumptionObject of consumptionData){
          const endpoint = consumptionObject?.path;
          if(endpoint.includes('casts-feed')){
            if (!groupedTilesData["casts-feed"][endpoint]){
              groupedTilesData["casts-feed"][endpoint] = 0;
            }
            groupedTilesData["casts-feed"][endpoint] += consumptionObject?.total_consumed || 0;
          }
          else if (endpoint.includes('users-feed')){
            if (!groupedTilesData["users-feed"][endpoint]){
              groupedTilesData["users-feed"][endpoint] = 0;
            }
            groupedTilesData["users-feed"][endpoint] += consumptionObject?.total_consumed || 0;
          }
          else if (endpoint.includes('search')){
            if (!groupedTilesData["search"][endpoint]){
              groupedTilesData["search"][endpoint] = 0;
            }
            groupedTilesData["search"][endpoint] += consumptionObject?.total_consumed || 0;
          }
          else if (endpoint.includes('labels')){
            if (!groupedTilesData["labels"][endpoint]){
              groupedTilesData["labels"][endpoint] = 0;
            }
            groupedTilesData["labels"][endpoint] += consumptionObject?.total_consumed || 0;
          }
        }
        for(const key in groupedTilesData){
         for(const endpoint in groupedTilesData[key]){
           groupedTilesData[key][endpoint] = groupedTilesData[key][endpoint] * computeUnitsPerApiCall[endpoint] || 0;
         }
        }
        setGroupCardsData(groupedTilesData);
        const pieChartData = getComputeUsagePieChartData(consumptionData);
        setPerApiComputeUsage(pieChartData);
        const totalComputeUsed = pieChartData.reduce((sum, current) => sum + current.value, 0);
        setTotalComputeUsed(totalComputeUsed);
        const planDetails = mbdUsagePlans[plan] || null;
        if(planDetails){
          setUserComputeQuota(planDetails.cuQuota);
        }else{
          setUserComputeQuota(1);
        }
      }
      setPerApiUsageChart({loading: false});
    };

    getGraphData();
    
    

  }, [frequency, period, appUrl, appName]);

 
  const handleFrequencyChange = (selectedFrequency) => {
    setFrequency(selectedFrequency?.value);
  };

  const handlePeriodChange = (selectedPeriod) => {
    setFrequency('day');
    setPeriod(selectedPeriod?.value);
  };

  const handleAppUrlChange = (selectedAppUrl) => {
    setAppUrl(selectedAppUrl?.value);
  };

  const handleAppNameChange = (selectedAppName) => {
    setAppName(selectedAppName?.value);
  };

  const handleStartClick = () => {
    window.open('https://playground.mbd.xyz/', '_blank');
  };

  const handleViewDocsClick = () => {
    window.open('https://docs.mbd.xyz', '_blank');
  };


  const frequencyOptions = [
    { value: 'day', label: '1 day' },
    // { value: 'minute', label: '15 min' },
    { value: 'hour', label: '1 hour' }
  ];

  // Options for Period select input
  const periodOptions = [
    { value: '1 month', label: '1 month' },
    { value: '1 day', label: '1 day' },
    { value: '1 week', label: '1 week' },
    // { value: '3 months', label: '3 months' },
    // { value: 'all', label: 'All' }
  ];

  const PercentUsageBar = (props) =>               
    <BarChart
      xAxis={[{
        scaleType: 'band',
        data: ['usage'],
        categoryGapRatio:0.5,
      }]}
      series={[
        {
          data: [props.percent],
          label: '% cu used',
          stack: 'usage',
          color: ['#76AFE8'],
          cornerRadius: 5,
          barGapRatio: 0.1,
          barLabel: `{props.percent}%`
        },
        {
          data:[100-props.percent],
          label: '% remaining',
          stack: 'usage',
          color: ['#F8F8F8'],
          cornerRadius: 5,
          barGapRatio: 0.1
        }

      ]}
      slotProps={{ legend: { 
        hidden: true,
        position: { vertical: 'bottom', horizontal: 'middle' },
      } }}
      barThickness={10}
      borderRadius={5}
      height={280}
      margin = {{top: 20}}
      leftAxis={null}
      bottomAxis={null}
      barLabel = {(item, context) => <p style={{color:'#ffffff'}}>`${item.value}%`</p>}
    />
  
    const PerAPICUPieChart = (props) => 
      <PieChart
        series={[
          {
            data: props.data,
            cornerRadius: 3,
          }
        ]}
        slotProps={{
          legend: {
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            labelStyle: {
              fontSize: 9,
              fill: '#666666'
            },
            itemMarkWidth:9,
            itemMarkHeight:9,
            itemGap:9,
            margin: { top: 20}
          },
        
        }}
        margin={{top: 20}}
        height={250}
      />
  
  return (
    <Animate type='pop'>

      {/* <Message
        closable
        title="Welcome to 'mbd console!"
        text={messageText}
        endTextLink = 'https://docs.mbd.xyz/reference'
        endText = 'here.'
      /> */}

      <Header>
        <span style={{justifyContent: 'space-between', display: 'flex'}}>
          <Typography variant={isMobile ? "h6" : "h5"}  style = {{color : darkMode ? "#fff" : "#000"}} component="div">
            Welcome {user?.name}!
          </Typography>
          <Avatar src={logo_image} alt="Company Logo" style={{ width: isMobile ? 35 : 45, height: isMobile ? 35 : 45, paddingBottom: 5}}/>
          </span>
        </Header>
      <GetStarted  darkMode = {darkMode}/>

      {/* {Object.keys(apiTilesData).length && (
        <Grid cols={Object.keys(apiTilesData).length + 1}>
          {Object.keys(apiTilesData).map((endpoint) => (
            <Stat
              key={endpoint} // Don't forget to add a unique key for each Stat component
              loading={apiTilesData?.loading}
              value={apiTilesData[endpoint]}
              label={endpoint}
              icon='refresh-cw'
            />
          ))}
        </Grid>
      )} */}

      <Card name='feed' 
            title='Explore Feeds' 
            subTitle=""
            loading={feeLoader}>
            <FeedTemplateShowcase
              publicFeeds={publicFeeds}
              privateFeeds={privateFeeds}
              createNewFeed={handleCreateNewFeedClick}  
              selectTemplate={(feed) => navigate('/feed-builder', { state: { feedId: feed.config_id, mode: 'public' } })}
              editFeed={handleFeedClick}
            />

      </Card>

      <Card name='compute_unit_usage' 
            title='Monthly Compute Units (CU) Usage' 
            subTitle=""
            loading={perApiUsageChart.loading}>
          <MGrid container minHeight={350}>
            <MGrid justifyContent={'center'} xs={12} sm={4} md={3}>
              <Card>
                  {/* <Typography variant="body2" align="center" style={{color: '#94a3b8'}}>
                    % CU usage
                  </Typography> */}
                  <span width="100%">
                    <h2 style={{textAlign: 'center', color: '#94a3b8'}}>% CU Usage</h2>
                  </span>
                  <PercentUsageBar percent={totalCopmuteUsed !== 0 && userComputeQuota !==0  ? totalCopmuteUsed/userComputeQuota*100 : 0}/>
                  <Typography variant="h5" align="center" style={{ fontWeight: 'bold' }}>
                    {`${totalCopmuteUsed !== 0 && userComputeQuota !==0 ? (totalCopmuteUsed/userComputeQuota*100).toFixed(1) : 0}%`}
                  </Typography>
                  {totalCopmuteUsed !== 0 && userComputeQuota !==0 &&
                  <Typography variant="h6" align="center" style={{color: '#94a3b8', fontSize:12}}>
                    {`${totalCopmuteUsed} / ${userComputeQuota}`}
                  </Typography>}
              </Card>
            </MGrid>
            <MGrid justifyContent={'center'} xs={12} sm={8} md={9}>
                <Card>
                  {/* 
                  <Typography variant="caption" align="center" style={{color: '#94a3b8'}}>
                    API CU Usage Distribution
                  </Typography> */}
                  <span width="100%">
                  <h2 style={{textAlign: 'center', color: '#94a3b8'}}>API CU Usage Distribution</h2>
                  </span>
                  <PerAPICUPieChart data={perApiComputeUsage}/>
                </Card>
            </MGrid>
          </MGrid>
      </Card>
      <Card name='api_usage' title='Monthly API Usage' subTitle="" loading={perApiUsageChart.loading}>
        <GroupCardsContainer groupedTilesData={groupCardsData} />
      </Card>

      <br/>

      {/* <Card name='api_usages' title='Monthly API Usage (resets on the 1st of each month)'>
        <Grid cols={4}>
            <Form
            inputs={{
              frequency: {
                label: 'Frequency',
                type: 'select',
                required: true,
                default:{ value: 'day', label: '1 day' },
                options:  period !== '1 day' ? frequencyOptions.filter(option => option.value === 'day') : frequencyOptions
              }
            }}
            updateOnChange
            onChange= {handleFrequencyChange}
          />
          <Form
            inputs={{
              frequency: {
                label: 'Period',
                type: 'select',
                required: true,
                default:{ value: '1 month', label: '1 month' },
                options: periodOptions
              }
            }}
            updateOnChange
            onChange= {handlePeriodChange}
          />
          <Form
            inputs={{
              frequency: {
                label: 'App Name',
                type: 'select',
                required: true,
                default:{"value": "all","label": "All"},
                options: appNameList || [{"value": "all","label": "All"}]
              }
            }}
            updateOnChange
            onChange= {handleAppNameChange}
          />
          <Form
            inputs={{
              frequency: {
                label: 'App Url',
                type: 'select',
                required: true,
                default:{"value": "all","label": "All"},
                options: appUrlList || [{"value": "all","label": "All"}]
              }
            }}
            updateOnChange
            onChange= {handleAppUrlChange}
          />
        </Grid>
        <Card>
          <Chart
            type='line'
            legend
            loading={ perApiUsageChart.loading }
            data={perApiUsageChart.data }
            color={perApiUsageChartColor}
          />
        </Card>
      </Card> */}

      <Feedback />

    </Animate>
  );
}
