import { useContext } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { Label, Error, ClassHelper, AuthContext } from 'components/lib';
import Style from './input.tailwind.js';

export function CardInput(props){

  const authContext = useContext(AuthContext);
  const error = props.errorMessage || 'Please provide valid credit card details';
  const darkMode = authContext.user.dark_mode;

  const cardStyle = ClassHelper(Style, {

    textbox: true, 
    cardbox: true,
    className: props.className, 
    error: props.valid === false,

  });

  return(
    <div className={ Style.input }>

      <Label
        text={ props.label }
        required={ props.required }
        for={ props.name }
      />

      <CardElement
        className={ cardStyle }
        options={{
          style: {
            base: {
              fontFamily: 'sans-serif',
              color: darkMode ? 'white' : '#334155',
              "::placeholder": {
                color: darkMode ? '#94a3b8' : '#64748b',
              },
            },
          },
        }}
        // style={{ base: { fontFamily: 'source sans pro', fontSize: '15px' }}}
      />

      { props.valid === false && <Error message={ error }/> }

    </div>
  );
}
