import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { Loader } from 'components/lib';
import { AuthContext, ViewContext } from 'components/lib';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Autocomplete,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
  Slider,
  Drawer,
  useMediaQuery, 
  useTheme,
  Grid,
  Chip,
  Stack,
  IconButton,
  Tooltip,
  ButtonBase
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PreviewIcon from '@mui/icons-material/Preview';
import { DeleteOutline as DeleteIcon, 
          DifferenceOutlined as CopyIcon, 
          CloseOutlined as CloseIcon,
          EditOutlined as EditIcon,
          RemoveCircleOutline as RemoveIcon,
          UndoOutlined as UndoIcon,
} from '@mui/icons-material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
// import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedDarkAtom, vs } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { aiLabelsOptions, embedDomainsOptions, endpointOptions, scoringOptions, publicationTypesOptions } from 'helper/constants';
import { PageIntro } from 'mbd-components/page-intro';
import { Text } from 'mbd-components/text';
import { PrimaryButton } from 'mbd-components/primary-button';
import { FeedTemplateShowcase } from './feedTemplateShowcase';



const customStyle = {
  borderRadius: '5px',
  backgroundColor: '#002b36',
  color: '#93a1a1',
  padding: '15px',
  margin: '10px 0',
  fontSize: '13px',
  width: '100%',
  height: 'auto',
  textAlign: 'left', // Align text to the left
  alignItems: 'left',
  overflowX: 'auto',
  '@media (max-width: 768px)': {
    fontSize: '11px',
    padding: '12px',
  },
  '@media (max-width: 480px)': {
    fontSize: '10px',
    padding: '10px',
  },
};

const primaryColor = '#e01296';

const CARD_COLORS = [
  "#A5B8E0", 
  "#76C7F2", 
  "#80D6B7", 
  "#8CD790",
  "#5BC8C7", 
  "#A3D477", 
  "#F39C9F", 
  "#FFB785", 
  "#FFD67B", 
  "#FFF07C", 
  "#FFB07C", 
  "#FF9AA2", 
  "#FF99A8", 
  "#F8A5A8", 
  "#D6A3F9" 
] 

const BG_IMAGES = [
  "http://media.tick.co/images/app/01_Adventure.jpg",
  "http://media.tick.co/images/app/02_Food.jpg",
  "http://media.tick.co/images/app/03_Sports.jpg",
  "http://media.tick.co/images/app/04_Health.jpg",
  "http://media.tick.co/images/app/05_Art.jpg",
  "http://media.tick.co/images/app/06_Lifestyle.jpg",
  "http://media.tick.co/images/app/07_Lifehacks.jpg",
  "http://media.tick.co/images/app/08_DIY.jpg",
  "http://media.tick.co/images/app/09_Products.jpg",
  "http://media.tick.co/images/app/10_Wildcard.jpg",
  "http://media.tick.co/images/app/11_Eco.jpg",
  "http://media.tick.co/images/app/12_TickHQ.jpg"


]

export function FeedConfig() {
  const [visibility, setVisibility] = useState('public');
  const [isEditMode, setIsEditMode] = useState(false);
  const [feedId, setFeedId] = useState('');
  const [feedName, setFeedName] = useState('');
  const [description, setDescription] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const [status, setStatus] = useState('active');
  const [scoring, setScoring] = useState('');
  const [impressionCount, setImpressionCount] = useState(0);
  const [promotionTypeValue, setPromotionTypeValue] = useState('feed');
  const [includesAILABELS, setIncludeAILABELS] = useState([]);
  const [excludeAILABELS, setExcludeAILABELS] = useState([]);
  const [includeAuthorIds, setIncludeAuthorIds] = useState([]);
  const [excludeAuthorIds, setExcludeAuthorIds] = useState([]);
  const [channels, setChannels] = useState([]);
  const [embedDomains, setEmbedDomains] = useState([]);
  const [includePublicationTypes, setIncludePublicationTypes] = useState([]);
  const [includeParams, setIncludeParams] = useState([]);
  const [excludeParams, setExcludeParams] = useState([]);
  const [openIncludeDialog, setOpenIncludeDialog] = useState(false);
  const [openExcludeDialog, setOpenExcludeDialog] = useState(false);
  const [openLabelOptionsDialog, setOpenLabelOptionsDialog] = useState(false);
  const [openExcludeLabelOptionsDialog, setOpenExcludeLabelOptionsDialog] = useState(false);
  const [selectedIncludeParams, setSelectedIncludeParams] = useState([]);
  const [selectedExcludeParams, setSelectedExcludeParams] = useState([]);
  const [publicFeeds, setPublicFeeds] = useState([]); // State for public feeds
  const [privateFeeds, setPrivateFeeds] = useState([]); // State for private feeds
  const [isNewFeed, setIsNewFeed] = useState(false);
  const [authorOptions, setAuthorOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [savedConfigId, setSavedConfigId] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSaveAs, setShowSaveAs] = useState(true);
  const [loading, setLoading] = useState(false);
  const [promotionName, setPromotionName] = useState('');
  const [selectedPromotionFeedId, setSelectedPromotionFeedId] = useState('');
  const [selectedPromotionFeedName, setSelectedPromotionFeedName] = useState('');
  const [promotionPercentage, setPromotionPercentage] = useState(0);
  const [aiLabelThresholds, setAiLabelThresholds] = useState({});
  const [removeAILabelThresholds, setRemoveAILabelThresholds] = useState({});
  const [showPromotionSection, setShowPromotionSection] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(true);
  const [itemsList, setItemsList] = useState([{ item_id: '', rank: '' }]);
  const [currentAiLabel, setCurrentAiLabel] = useState('');
  const [currentAiLabelThreshold, setCurrentAiLabelThreshold] = useState(0);
  const [isShowcaseMode, setIsShowcaseMode] = useState(true);
  const [iframeKey, setIframeKey] = useState(Date.now());

  const playgroundUrl = process.env.REACT_APP_PLAYGROUND_URL;

  
  const location = useLocation();
  const { feedId: initialFeedId, mode, new: intialNewFeed } = location.state || {};


  const promotionTypeList = [
    { value: 'feed', label: 'Feed' },
    { value: 'items', label: 'Items' }
  ]

  // const playgroundUrl = process.env.REACT_APP_PLAYGROUND_URL;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const togglePreviewDrawer = () => {
    setIsPreviewOpen(!isPreviewOpen);
  };


  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  const statusOptions = ['active', 'inactive'];

  // Fetch feeds based on visibility type
  const fetchFeeds = async (type) => {
    try {
      let result = await Axios({
        url: '/api/feed/configs',
        method: 'post',
        data: {
          visibility: type,
          console_account_id: authContext?.user?.account_id || null,
        },
      });
      return result?.data?.data || [];
    } catch (error) {
      console.error(`Error fetching ${type} feeds:`, error);
      setSnackbarMessage(`Error fetching ${type} feeds`);
      setShowSnackbar(true);
      return [];
    }
  };

  // Fetch both public and private feeds simultaneously
  const fetchAllFeeds = async () => {
    setLoading(true);
    try {
      const [publicFeedsData, privateFeedsData] = await Promise.all([
        fetchFeeds('public'),
        fetchFeeds('private'),
      ]);
      setPublicFeeds(publicFeedsData);
      setPrivateFeeds(privateFeedsData);

    } catch (error) {
      console.error('Error fetching all feeds:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch channels details based on channel ids
  const fetchChannelsDetails = async (channels) => {
    if (!channels || channels.length === 0) return [];
    try {
      let channelsString = channels.join(',');
      let result = await Axios({
        url: '/api/farcaster/channel/bulk',
        method: 'get',
        params: { ids: channelsString, type: 'parent_url' },
      });
      return result?.data?.data || [];
    } catch (error) {
      console.error('Error fetching channels details:', error);
      setSnackbarMessage('Error fetching channel details');
      setShowSnackbar(true);
      return [];
    }
  };

  // Fetch author details based on author ids
  const fetchAuthorDetails = async (authorIds) => {
    if (!authorIds || authorIds.length === 0) return [];
    try {
      let result = await Axios({
        url: '/api/farcaster/users/details',
        method: 'post',
        data: { fids: authorIds },
      });
      return result?.data?.data || [];
    } catch (error) {
      console.error('Error fetching author details:', error);
      setSnackbarMessage('Error fetching author details');
      setShowSnackbar(true);
      return [];
    }
  };

  // Fetch autocomplete options based on user input and type
  const fetchAutocompleteOptions = async (query, type) => {
    if (!query) return [];
    try {
      if (type === 'author_ids') {
        let result = await Axios({
          url: '/api/farcaster/users/search',
          method: 'get',
          params: { query: query },
        });
        return result?.data?.data.map((item) => ({ label: item.fname, value: item.fid })) || [];
      }
      if (type === 'channels') {
        let result = await Axios({
          url: '/api/farcaster/channel/search',
          method: 'get',
          params: { q: query, limit: 20 },
        });
        return result?.data?.data?.map((item) => ({ label: item.label, value: item.value })) || [];
      }
      return [];
    } catch (error) {
      console.error(`Error fetching ${type} options:`, error);
      setSnackbarMessage(`Error fetching ${type} options`);
      setShowSnackbar(true);
      return [];
    }
  };

  const handleVisibilityChange = (event, newVisibility) => {
    if (newVisibility !== null && visibility !== newVisibility) {
      setVisibility(newVisibility);
      setFeedId(''); // Reset feed ID when changing visibility
      resetFormFields(); // Reset form fields when visibility changes
      const feedsToUse = newVisibility === 'public' ? publicFeeds : privateFeeds;
      // Auto-select the first feed in the new visibility state if available
      if (feedsToUse.length > 0) {
        const firstFeed = feedsToUse[0];
        setFeedId(firstFeed.config_id);
        populateFeedDetails(firstFeed);
      }

      if(newVisibility === 'public') {
        setShowSaveAs(true);
        setIsEditMode(false);
        setIsShowcaseMode(true);
      }
      else {
        setIsShowcaseMode(false);
      }
      // setIsPreviewOpen(true);
    }
  };

  const handleFeedSelection = async (event) => {
    const selectedFeedId = event.target.value;
    if (selectedFeedId === 'new') {
      setIsNewFeed(true);
      resetFormFields();
    } else {
      setIsNewFeed(false);
      setFeedId(selectedFeedId);
      const feeds = visibility === 'public' ? publicFeeds : privateFeeds;
      const selectedFeed = feeds.find((feed) => feed.config_id === selectedFeedId);
      if (selectedFeed) {
        populateFeedDetails(selectedFeed);
      }
      // setIsPreviewOpen(true);
    }
  };

  const resetFormFields = () => {
    setFeedId('');
    setFeedName('');
    setDescription('');
    setEndpoint('');
    setStatus('');
    setScoring('');
    setIncludeAILABELS([]);
    setExcludeAILABELS([]);
    setIncludeAuthorIds([]);
    setExcludeAuthorIds([]);
    setIncludePublicationTypes([]);
    setChannels([]);
    setEmbedDomains([]);
    setIncludeParams([]);
    setExcludeParams([]);
    setAuthorOptions([]);
    setChannelOptions([]);
    setPromotionName('');
    setSelectedPromotionFeedId('');
    setSelectedPromotionFeedName('');
    setSelectedExcludeParams([]);
    setSelectedIncludeParams([]);
    setPromotionPercentage(0);
    setItemsList([{ item_id: '', rank: '' }]);
  };

  const populateFeedDetails = async (feed) => {
    setFeedName(feed.name);
    setDescription(feed.description);
    setEndpoint(feed.endpoint);
    setStatus(feed.status);
    setScoring((feed.endpoint === 'casts/feed/trending' || feed.endpoint === 'casts/feed/popular') ? '1day' : 'all');
    setImpressionCount(feed.config.impression_count || 0);

    // Initialize AI label thresholds
    let initialIncludeThresholds = {};
    let initialExcludeThresholds = {};

    // Populate promotion fields based on existing config
    if (feed.config.promotion_filters && feed.config.promotion_filters.length > 0) {
      const promotion = feed.config.promotion_filters[0];
      if(promotion.promotion_type === 'feed'){
        setPromotionTypeValue(promotion.promotion_type || '');
        setPromotionName(promotion.promotion_name || '');
        setSelectedPromotionFeedId(promotion.feed_id || '');
        setSelectedPromotionFeedName(
          [...publicFeeds, ...privateFeeds].find((f) => f.config_id === promotion.feed_id)?.name || ''
        );
        setPromotionPercentage(promotion.percent || 0);
        setItemsList([{ item_id: '', rank: '' }]);
      }else if(promotion.promotion_type === 'items'){
        setPromotionName(promotion.promotion_name || '');
        setPromotionTypeValue(promotion.promotion_type || '');
        setItemsList(promotion.promoted_items || []);
        setPromotionPercentage(0);
        setSelectedPromotionFeedId('');
        setSelectedPromotionFeedName('');
      }
      setShowPromotionSection(true);
    }else{
        setPromotionName('');
        setSelectedPromotionFeedId('');
        setSelectedPromotionFeedName('');
        setPromotionPercentage(0);
        setItemsList([{ item_id: '', rank: '' }]);
        setShowPromotionSection(false);
    }

    if (feed?.config?.filters?.channels && feed?.config?.filters?.channels?.length > 0) {
      setIncludeParams((prev) => [...new Set([...prev, 'channels'])]);
      let channelsDetails = await fetchChannelsDetails(feed.config.filters.channels);
      setChannels(channelsDetails.map((ch) => ({ label: ch.name, value: ch.url })));
      setSelectedIncludeParams((prev) => [...new Set([...prev, 'channels'])]);
    }else{
        setChannels([]);
        setIncludeParams((prev) => prev.filter((param) => param !== 'channels')); 
        setSelectedIncludeParams((prev) => prev.filter((param) => param !== 'channels'));
    }

    // Set thresholds for include AI labels
    if (feed?.config?.filters?.ai_labels && feed.config.filters.ai_labels.length > 0) {
        const aiLabelsWithThresholds = feed.config.filters.ai_labels.map(label => {
        const [labelName, threshold] = label.split(':');
        if (threshold) {
            initialIncludeThresholds[labelName] = parseFloat(threshold); // Store the threshold value
        }
        return labelName; // Store the label name
        });
        setIncludeAILABELS(aiLabelsWithThresholds);
        setSelectedIncludeParams(prev => [...new Set([...prev, 'ai_labels'])]);
    } else {
        setIncludeAILABELS([]);
        setSelectedIncludeParams(prev => prev.filter(param => param !== 'ai_labels'));
    }

    if(feed?.config?.filters?.publication_types && feed?.config?.filters?.publication_types?.length > 0){
        setIncludeParams((prev) => [...new Set([...prev, 'publication_types'])]);
        setIncludePublicationTypes(feed.config.filters.publication_types.map((type) => ({ label: type, value: type })));
        setSelectedIncludeParams((prev) => [...new Set([...prev, 'publication_types'])]);
    }else{
        setIncludePublicationTypes([]);
        setIncludeParams((prev) => prev.filter((param) => param !== 'publication_types'));
        setSelectedIncludeParams((prev) => prev.filter((param) => param !== 'publication_types'));
    }


    // Set thresholds for exclude AI labels
    if (feed?.config?.filters?.remove_ai_labels && feed.config.filters.remove_ai_labels.length > 0) {
        const aiLabelsWithThresholds = feed.config.filters.remove_ai_labels.map(label => {
        const [labelName, threshold] = label.split(':');
        if (threshold) {
            initialExcludeThresholds[labelName] = parseFloat(threshold); // Store the threshold value
        }
        return labelName; // Store the label name
        });
        setExcludeAILABELS(aiLabelsWithThresholds);
        setSelectedExcludeParams(prev => [...new Set([...prev, 'ai_labels'])]);
    } else {
        setExcludeAILABELS([]);
        setSelectedExcludeParams(prev => prev.filter(param => param !== 'ai_labels'));
    }

    // Set the initial AI label thresholds
    setAiLabelThresholds(initialIncludeThresholds);
    setRemoveAILabelThresholds(initialExcludeThresholds);

    if (feed?.config?.filters?.author_ids && feed?.config?.filters?.author_ids?.length > 0) {
      setIncludeParams((prev) => [...new Set([...prev, 'author_ids'])]);
      let authorDetails = await fetchAuthorDetails(feed.config.filters.author_ids);
      setIncludeAuthorIds(authorDetails.map((id) => ({ label: id.fname, value: id.fid })));
      setSelectedIncludeParams((prev) => [...new Set([...prev, 'author_ids'])]);
    }else{
        setIncludeAuthorIds([]);
        setIncludeParams((prev) => prev.filter((param) => param !== 'author_ids')); 
        setSelectedIncludeParams((prev) => prev.filter((param) => param !== 'author_ids'));
    }

    if (feed?.config?.filters?.remove_author_ids && feed?.config?.filters?.remove_author_ids?.length > 0) {
      setExcludeParams((prev) => [...new Set([...prev, 'author_ids'])]);
      let authorDetails = await fetchAuthorDetails(feed.config.filters.remove_author_ids);
      setExcludeAuthorIds(authorDetails.map((id) => ({ label: id.fname, value: id.fid })));
      setSelectedExcludeParams((prev) => [...new Set([...prev, 'author_ids'])]);
    }else{
        setExcludeAuthorIds([]);
        setExcludeParams((prev) => prev.filter((param) => param !== 'author_ids')); 
        setSelectedExcludeParams((prev) => prev.filter((param) => param !== 'author_ids'));
    }

    if (feed?.config?.filters?.embed_domains && feed?.config?.filters?.embed_domains?.length > 0) {
      setIncludeParams((prev) => [...new Set([...prev, 'embed_domains'])]);
      setEmbedDomains(feed.config.filters.embed_domains);
      setSelectedIncludeParams((prev) => [...new Set([...prev, 'embed_domains'])]);
    }else{
        setEmbedDomains([]);
        setIncludeParams((prev) => prev.filter((param) => param !== 'embed_domains')); 
        setSelectedIncludeParams((prev) => prev.filter((param) => param !== 'embed_domains'));
    }
  };

  const handleScoringChange = (event) => {
    setScoring(event.target.value);
  };

  const handleEndpointChange = (event) => {
    console.log('handleEndpointChagne event: ', event.target.value);  
    setEndpoint(event.target.value);
    setScoring('');
    if(event.target.value !== 'casts/feed/trending' && event.target.value !== 'casts/feed/popular'){
        setAiLabelThresholds({});
        setRemoveAILabelThresholds({});
    }
  };

  const handleIncludeParamsDialog = () => {
    setOpenIncludeDialog(true);
  };

  const handleExcludeParamsDialog = () => {
    setOpenExcludeDialog(true);
  };

  const handleItemChange = (value, index, field) => {
    const newList = [...itemsList];
    newList[index][field] = value;
    setItemsList(newList);
  };

  const addItem = () => {
    setItemsList([...itemsList, { item_id: '', rank: '' }]);
  };
  
  const removeItem = (index) => {
    const newList = itemsList.filter((_, i) => i !== index);
    setItemsList(newList);
  };
  
  

  const handleIncludeParamsChange = (event) => {
    const { name, checked } = event.target;
    setSelectedIncludeParams((prev) => (checked ? [...prev, name] : prev.filter((item) => item !== name)));
  
    if (!checked) {
      switch (name) {
        case 'author_ids':
          setIncludeAuthorIds([]);
          break;
        case 'channels':
          setChannels([]);
          break;
        case 'ai_labels':
          setIncludeAILABELS([]);
          setAiLabelThresholds({}); // Reset thresholds when AI labels are unchecked
          break;
        case 'embed_domains':
          setEmbedDomains([]);
          break;
        case 'publication_types':
          setIncludePublicationTypes([]);
          break;
        default:
          break;
      }
    }
  };
  
  const handleExcludeParamsChange = (event) => {
    const { name, checked } = event.target;
    setSelectedExcludeParams((prev) => (checked ? [...prev, name] : prev.filter((item) => item !== name)));
  
    if (!checked) {
      switch (name) {
        case 'author_ids':
          setExcludeAuthorIds([]);
          break;
        case 'ai_labels':
          setExcludeAILABELS([]);
          setRemoveAILabelThresholds({}); // Reset thresholds when AI labels are unchecked
          break;
        default:
          break;
      }
    }
  };  

  const handleSave = async () => {
    // console.log('handleSave -publication types:', includePublicationTypes);
    const config = {
      console_account_id: authContext?.user?.account_id || null,
      name: feedName,
      api_key: 'all',
      description,
      endpoint,
      status: 'active',
      visibility: 'private',
      config: {
        filters: {
          channels: channels.length > 0 ? channels.map((ch) => ch.value) : [],
          ai_labels: includesAILABELS.map(label => aiLabelThresholds[label] ? `${label}:${aiLabelThresholds[label]}` : label),
          publication_types: includePublicationTypes.length > 0 ? includePublicationTypes.map((type) => type.value): [],
          remove_ai_labels: excludeAILABELS.map(label => removeAILabelThresholds[label] ? `${label}:${removeAILabelThresholds[label]}` : label),
          author_ids: includeAuthorIds.length > 0 ? includeAuthorIds.map((id) => id.value) : [],
          remove_author_ids: excludeAuthorIds.length > 0 ? excludeAuthorIds.map((id) => id.value) : [],
          embed_domains: embedDomains.length > 0 ? embedDomains : [],
        },
      },
    };
  
    if (showPromotionSection && promotionTypeValue && promotionTypeValue === 'feed' && promotionName && promotionName !== '' && selectedPromotionFeedId && selectedPromotionFeedId !== '') {
      config.config.promotion_filters = [{
        promotion_type: promotionTypeValue,
        promotion_name: promotionName,
        feed_id: selectedPromotionFeedId,
        percent: promotionPercentage
      }];
    }

    if (showPromotionSection && promotionTypeValue && promotionTypeValue === 'items' &&  promotionName && promotionName !== '' && itemsList.length > 0) {
      config.config.promotion_filters = [{
        promotion_name: promotionName,
        promotion_type: promotionTypeValue,
        promoted_items: itemsList
      }];
    }
  
    try {
      if (isNewFeed) {
        if (scoring && scoring !== '') {
          config.config.scoring = scoring;
        }
        config.config.scoring = (config.endpoint === 'casts/feed/trending' || config.endpoint === 'casts/feed/popular') ? '1day' : 'all';
        if(impressionCount && impressionCount >= 0){
          config.config.impression_count = impressionCount;
        }
        const response = await Axios.post('/api/feed/config', config);
        setSavedConfigId(response?.data?.data?.config_id);
        setFeedId(response?.data?.data?.config_id);
        setSnackbarMessage(`Feed created successfully! Your feed ID is ${response?.data?.data?.config_id}. Please use this feed ID in the API.`);
        setShowSnackbar(true);
        setShowSaveAs(true);
        setIsNewFeed(false);
        // setIsPreviewOpen(true);
        setIsEditMode(false);
      } else {
        if (scoring && scoring !== '') {
          config.config.scoring = scoring;
        }
        if(impressionCount && impressionCount > 0){
          config.config.impression_count = impressionCount;
        }
        config.config_id = feedId;
        await Axios.patch('/api/feed/config', config);
        setSnackbarMessage('Feed updated successfully');
        setShowSnackbar(true);
        setShowSaveAs(true);
        // setIsPreviewOpen(true);
        setIsEditMode(false);
      }
      setIframeKey(Date.now());
    } catch (error) {
      console.error('Error saving feed configuration:', error);
      setSnackbarMessage(`Failed to save configuration ${error}`);
      setShowSnackbar(true);
    } finally {
      // Re-fetch both public and private feeds after saving or updating
      fetchAllFeeds();
    }
  }; 

  const handleSaveAs = () => {
    setIsNewFeed(true);
    setFeedId('');
    setVisibility('private');
    setFeedName(`${feedName}-copy`);
    setSnackbarMessage('Prepare to save as a new feed');
    setShowSnackbar(true);
    setShowSaveAs(false);
    setIsEditMode(true); 
    // setIsPreviewOpen(true);
  };

  const handleDelete = async () => {
    viewContext.modal.show({

      title: 'Delete Feed',
      form: {
        config_id: {
          type: 'hidden',
          value: feedId,
        }
      },
      buttonText: 'Delete Feed',
      url: '/api/feed/config',
      method: 'DELETE',
      destructive: true,
      text: 'Are you sure you want to delete this feed? ' +
      'If you are using this feed_id in your application, it will stop working.',

    }, () => {

      handlePostDelete();

    });
  }

  const handlePostDelete = async () => {
    try {
  
      // Show success message in snackbar
      setSnackbarMessage('Feed Config deleted successfully');
      setShowSnackbar(true);
  
    const [publicFeedsData, privateFeedsData] = await Promise.all([
        fetchFeeds('public'),
        fetchFeeds('private'),
    ]);

    // Set the state for public and private feeds
    setPublicFeeds(publicFeedsData);
    setPrivateFeeds(privateFeedsData);
      
      // Reset the form fields and select the first feed from the updated list
      const updatedFeeds = visibility === 'public' ? publicFeedsData : privateFeedsData;
      if (updatedFeeds.length > 0) {
        const firstFeed = updatedFeeds[0];
        resetFormFields(); // Clear all form fields
        setFeedId(firstFeed.config_id);
        populateFeedDetails(firstFeed);
        // setIsPreviewOpen(true);
      } else {
        // If no feeds are left, reset the fields
        resetFormFields();
      }
  
    } catch (error) {
      console.error('Error deleting feed configuration:', error);
      setSnackbarMessage('Failed to delete the feed configuration');
      setShowSnackbar(true);
    }
  };

  const handleCancelEdit = async () => {
    // viewContext.modal.show({

    //   title: 'Cancel Edit',
    //   buttonText: 'OK',
    //   destructive: true,
    //   text: 'Cancel editing this feed? Unsaved changes will be lost.'

    // }, async () => {

      // TODO: show a confirmation dialog before cancelling edit

      setIsEditMode(false);
      const myFeeds = await fetchFeeds('private');
      const currentFeed = myFeeds.find((feed) => feed.config_id === feedId);
      populateFeedDetails(currentFeed);

    // });
  }
  

  useEffect(() => {
    const fetchInitialFeeds = async () => {
      // Fetch feeds data directly
      const [publicFeedsData, privateFeedsData] = await Promise.all([
        fetchFeeds('public'),
        fetchFeeds('private'),
      ]);
  
      // Set the state for public and private feeds
      setPublicFeeds(publicFeedsData);
      setPrivateFeeds(privateFeedsData);
  
      // Determine which feeds to use based on visibility and `feedId`
      const initialFeeds = visibility === 'public' ? publicFeedsData : privateFeedsData;

      if(intialNewFeed){
        setIsNewFeed(true);
        setIsShowcaseMode(false);
        setVisibility('private');
        return;
      }
      
      // If `feedId` exists in query, find and select it
      if (initialFeedId) {
        const selectedFeed = [...publicFeedsData, ...privateFeedsData].find(feed => feed.config_id === initialFeedId);
        if (selectedFeed) {
          setIsShowcaseMode(false);
          setVisibility(mode === 'public' ? 'public' : 'private');
          setFeedId(selectedFeed.config_id);
          populateFeedDetails(selectedFeed);

          return;
        }
      }
  
      // Default to the first available feed if no `feedId` is provided
      if (initialFeeds.length > 0) {
        const firstFeed = initialFeeds[0];
        resetFormFields(); // Clear all form fields
        setFeedId(firstFeed.config_id); // Set the Feed ID
        populateFeedDetails(firstFeed); // Populate the form fields with feed details
      }
    };

    fetchInitialFeeds();
  
  }, []);  

  const findNewItem = (oldList, newList) => {
    return newList.find((item) => !oldList.some((oldItem) => oldItem === item));
  }

  // const PrimaryButton = (props) =>
  //   <Button
  //     variant="contained"
  //     color="primary"
  //     onClick={props.onClick}
  //     sx={{ backgroundColor: primaryColor, '&:hover': { backgroundColor: '#ed9ed2' } }}
  //   >
  //       {props.children}
  //   </Button>

  const selectTemplate = (feed) => {  
    setFeedId(feed.config_id);
    populateFeedDetails(feed);
    setIsShowcaseMode(false);
  }

  const createNewFeed = () => {
    resetFormFields();
    setIsNewFeed(true);
    setFeedName('New Feed');
    setDescription('New Feed');
    setEndpoint('casts/feed/trending');
    setStatus('active');
    setIsShowcaseMode(false);
    setIsEditMode(true);
    setVisibility('private');
  }

  const editFeed = (feed) => {
    setFeedId(feed.config_id);
    populateFeedDetails(feed);
    setIsEditMode(true);
    setIsShowcaseMode(false);
    setVisibility('private');
  }


  // const FeedTemplateCard = (props) => {
  //   const feed = props.feed;
  //   const color = props.color;

  //   return (
  //   <Box fulllWidth sx={{backgroundColor: '#222222', borderRadius: 3, borderWidth: 1, borderColor: '#f0f0f0', borderLeftWidth: 0, borderLeftColor: color, width: 320, minHeight: 150, padding: 2, justifyContent: 'flex-start', textAlign: 'left', backgroundImage: `url(${props.backgroundImage})`, backgroundSize: 'cover'}}>
  //     <Text variant='h1' color='white'>{feed.name}</Text>
  //     <Text variant='body' color='gray-lighter'>{feed.description}</Text>
  //     <Box display='flex' flexDirection='row' mt={4}>
  //       <Box flexGrow={1}>
  //         <Text variant='small' color='gray-lighter'>Endpoint: {feed.endpoint}</Text>
  //       </Box>
  //       <Text variant='small' color='brand-light'>by:  mbd team</Text>

  //      </Box>

  //   </Box>
  //   )
  // }

  // const FeedTemplateShowcase = (props) => {


  //   // const privateFeeds1 = [];
  //   return(
  //     <Box display='flex' flexDirection='column' alignContent='flex-start'>
  //       <Box>
  //         <Text variant='h1'>
  //           Your Feeds
  //         </Text>
  //       </Box>
  //       { privateFeeds && privateFeeds.length > 0 ?
  //         <Grid mt={4} container spacing={2}>
  //           {privateFeeds.map((feed, index) => 
  //             <Grid key={`template-card-${feed.config_id}`} item xs={12} sm={6} md={4} >
  //               <ButtonBase sx={{textTransform: 'none', justifyContent:'flex-start'}} onClick={() => editFeed(feed)}>
  //                 <FeedTemplateCard feed={feed} color={CARD_COLORS[index % CARD_COLORS.length]} backgroundImage={feed.feed_image_url}/>
  //               </ButtonBase>
  //             </Grid >
  //           )}
  //         </Grid>
  //       :
  //         <Box my={10}>
  //           <Text variant='body' color='gray'>
  //             You have not created any feeds yet.  Create a new feed, or select a template from below to get started.
  //           </Text>
  //           <Box mt={4}>
  //             <PrimaryButton onClick={() => createNewFeed()}>Create New Feed</PrimaryButton>
  //           </Box>
            
  //         </Box>
  //       }
  //       <Box mt={6}>
  //         <Text variant='h1'>
  //           Templates
  //         </Text>
  //         <Text variant='body' sx={{ mt: 2}}>
  //           Some useful templates to give you some inspiration and help you get started.
  //         </Text>
  //       </Box>
  //       { publicFeeds && publicFeeds.length > 0 ?
  //         <Grid mt={4} container spacing={2}>
  //           {publicFeeds.map((feed, index) => 
  //             <Grid key={`template-card-${feed.config_id}`} item xs={12} sm={6} md={4} >
  //               <ButtonBase sx={{textTransform: 'none', justifyContent:'flex-start'}} onClick={() => selectTemplate(feed)}>
  //                 <FeedTemplateCard feed={feed} color={CARD_COLORS[index % CARD_COLORS.length]} backgroundImage={feed.feed_image_url}/>
  //               </ButtonBase>
  //             </Grid >
  //           )}
  //         </Grid>
  //       :
  //         <Box my={10}>
  //           <Text variant='body' color='gray'>
  //             Somehow no templates are found.  Please try again later.
  //           </Text>
  //         </Box>
  //       }
  //     </Box>

  //   )

  // }

  const NonEditableTextField = (props) => 
    <Box  mr={4} mt={4} sx={{display:'flex', flexDirection: props.horizontal ? 'row' : 'column', alignItems: props.horizontal ? 'center' : 'flex-start'}} >
      <Text variant='fieldHeading' color='brand-light'>
        {props.heading}
      </Text>
      <Text variant='fieldValue' color='gra' sx={{marginLeft: props.horizontal ? 2 : 0, marginTop: props.horizontal ? 0 : 2}}>
        {props.value}
      </Text>
    </Box>



  //
  // This causes problems at the moment used in as a component - text field loses focus after every key stroke.  Probably something is triggering a re-render
  //  
  const EditableTextField = (props) =>
    <Box  mr={4} mt={4} sx={{display:'flex', flexDirection: props.horizontal ? 'row' : 'column', alignItems: props.horizontal ? 'center' : 'flex-start'}} fullWidth >
      <Text variant='fieldHeading' color='brand-light' sx={{minWidth: 80}}>
        {props.heading}
      </Text>
      <TextField 
        fullWidth
        multiline={props.multiline ? true : false}
        rows={props.rows ? props.rows : 1}
        value={props.value} 
        onChange={props.onChange} 
        variant="outlined" 
        sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor} , marginLeft: props.horizontal ? 2 : 0, marginTop: props.horizontal ? 0 : 2}} 
      />
    </Box>

  const SelectField = (props) =>
  {
    return (
      <Box sx={{display:'flex', flexDirection: props.horizontal ? 'row' : 'column', alignItems: props.horizontal ? 'center' : 'flex-start'}} fullWidth >
      <Text variant='fieldHeading' color='brand-light'>
        {props.heading}
      </Text>

      <FormControl fullWidth variant="outlined">
      {/* <InputLabel shrink={!!endpoint}>Endpoint</InputLabel> */}
      <Select 
        value={props.value} 
        onChange={props.onChange} 
        sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor }, marginLeft: props.horizontal ? 2 : 0, marginTop: props.horizontal ? 0 : 2 }}
      >
        {props.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
    )
  }

  
  const CountField = (props) =>
    <Box sx={{display:'flex', flexDirection: props.horizontal ? 'row' : 'column', alignItems: props.horizontal ? 'center' : 'flex-start'}} fullWidth >
      <Text variant='fieldHeading' color='brand-light'>
          {props.heading}
      </Text>
      <FormControl fullWidth variant="outlined">
        <Box display="flex" alignItems="center" gap={2} sx={{marginLeft: props.horizontal ? 2 : 0, marginTop: props.horizontal ? 0 : 2}}>
          <TextField
            type="number"
            value={props.value}
            onChange={props.onChagne}
            variant='outlined'
            step={props.step ? props.step : 1}
            inputProps={{
              min: 0,
              style: { textAlign: 'center' } // Center align the input text
            }}
            sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor } }} 
          />
        </Box>
      </FormControl>
    </Box>

  const AutoCompleteField = (props) =>
    <Box mt={4}>
      <Text variant='fieldHeading' color='brand-light'>
        {props.heading}
      </Text>
      <Autocomplete
        multiple
        options={props.options}
        value={props.value}
        onInputChange={props.onInputChange}
        onChange={props.onChange}
        renderInput={props.renderInput}
        sx={{ my: 2 }}
      />
    </Box>
  const NonEditableSelections = (props) =>
    <Box>
      <Box my={2}>
        <Text variant='fieldHeading' color='brand-light'>{props.heading}</Text>
      </Box>
      {
        props.selections.length > 0 ? 
          <Stack direction='row' spacing={1} sx={{flexWrap: 'wrap', gap: '16px 8px'}}>
            {props.selections.map((selection) => <Chip margin={2} label={selection} variant='outlined' key={selection} sx={{color:'#97979A'}}/>)}
          </Stack>
        : 
          <Text variant='fieldValue' color='gray-light'>None</Text>
      }
    </Box>

  const Promotion = () =>

      <Box>
        <NonEditableTextField horizontal={true} heading="Promotion Type" value={promotionTypeValue}/> 
        {
          promotionTypeValue === 'feed' ?
          <Box>
            <Box display="flex" flexDirection={'row'}>
              <NonEditableTextField horizontal={true} heading="Feed ID" value={selectedPromotionFeedId}/>
              <NonEditableTextField horizontal={true} heading="Feed Name" value={selectedPromotionFeedName}/>
            </Box>
            <Box display="flex" flexDirection={'row'}>
              <NonEditableTextField horizontal={true} heading="Promotion Name" value={promotionName}/>
              <NonEditableTextField horizontal={true} heading="Percentage" value={promotionPercentage}/>
            </Box>
          </Box>
        :
          <Box mt={2}>

            <Stack direction='row' spacing={4}>
              <Box>
                <Text variant='fieldHeading' color='brand-light'> Item ID </Text>
                {
                  itemsList.map((item) => <Text variant='fieldValue' color='gray-light'> {item.item_id} </Text>)
                }
              </Box>
              <Box ml={4}>
                <Text variant='fieldHeading' color='brand-light'> Rank </Text>
                {
                  itemsList.map((item) => <Text variant='fieldValue' color='gray-light'> {item.rank} </Text>)
                }
              </Box>
            </Stack>
          </Box>
        }
      </Box>
    


  
  const NonEditableFeedDetails = () =>
    <Box my={1}>

      <Box display="flex" flexDirection={'row'} alignItems={'center'}>
        <Box sx={{flexGrow: visibility === 'private' ? 1 : 0}} mr={ visibility === 'public' ? 10 : 0}>
          <Text variant='h1'>Feed Info</Text>
        </Box>
        {visibility === 'private' ? 
          <Stack direction={'row'}>
              <IconButton onClick={handleSaveAs} color="error" sx={{width: 40, padding: 1, fontSize: 'small'}}>
                <Tooltip title="Clone this feed as a new feed">
                  <CopyIcon />
                </Tooltip>
              </IconButton> 
              <IconButton onClick={handleDelete} color="error" sx={{width: 40, marginRight: 1}}>
                <Tooltip title="Delete Feed">
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
              <PrimaryButton onClick={() => setIsEditMode(true)}> Edit Feed </PrimaryButton>  
            </Stack>
          : 
          <PrimaryButton onClick={handleSaveAs}> Use Template </PrimaryButton>  
        }
      </Box>
      <Box display="flex" flexDirection={'row'}>
        <NonEditableTextField horizontal={true} heading="Feed ID" value={feedId}/>
        <NonEditableTextField horizontal={true} heading="Feed Name" value={feedName}/>
      </Box>
      <NonEditableTextField heading="Endpoint" value={endpoint}/>
      <NonEditableTextField heading="Description" value={description}/>
      <Box mt={4}>
        <Text variant='h1'>Feed Configurations</Text>
      </Box>

      <Box display="flex" flexDirection={'row'}>
        {/* <NonEditableTextField horizontal={true} heading="scoring" value={scoring}/> */}
        <NonEditableTextField horizontal={true} heading="Impression Count" value={impressionCount}/>
      </Box>
      <Box mt={4}>
        <Text variant='h2'>Include Params</Text>
        <NonEditableSelections heading="Author IDs" selections={ includeAuthorIds.map((author) => author.label)}/>
        <NonEditableSelections heading="Channels" selections={channels}/>
        <NonEditableSelections heading="AI Labels" selections={includesAILABELS}/>
        <NonEditableSelections heading="Embed Domains" selections={embedDomains}/>
        <NonEditableSelections heading="Publication Types" selections={includePublicationTypes.map(type => type.label)}/>
      </Box>
      <Box mt={4}>
        <Text variant='h2'>Exclude Params</Text>
        <NonEditableSelections heading="Author IDs" selections={excludeAuthorIds.map((author) => author.label)}/>
        <NonEditableSelections heading="AI Labels" selections={excludeAILABELS}/>
      </Box>
      <Box mt={4}>
        <Text variant='h2'>Promotions</Text>
        {
          showPromotionSection ?
            <Promotion /> 
          : 
            <Text variant='fieldValue' color='gray-light'>None</Text>
        }
      </Box>
    </Box>

  return (
    <Grid container justifyContent="center" spacing={4} p={4} >
      <Grid item xs={12}>

        <Box display="flex" justifyContent="center" mb={3}>
          <ToggleButtonGroup
            value={visibility}
            exclusive
            onChange={handleVisibilityChange}
            aria-label="visibility"
          >
            <ToggleButton value="public" aria-label="public" >
              Explore Feeds
            </ToggleButton>
            <ToggleButton value="private" aria-label="private">
              Manage My Feeds
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Grid>
      { visibility !== 'public' &&
          <Grid item xs={12} sm={isShowcaseMode ? 12 : 8}>
            <Box mb={4}>
              <Typography variant="h5" mb={2} sx={{fontWeight: 500}}>Your Feeds</Typography>
              <Typography variant="body2" color="textSecondary">
                Select a feed
              </Typography>
              <FormControl fullWidth margin="dense" variant="outlined">
                {/* <InputLabel shrink={!!feedId}>Select a feed</InputLabel> */}
                <Select value={feedId} onChange={handleFeedSelection} sx={{borderWidth: 2, borderColor: '#43092b'}}>
                  {visibility === 'private' && <MenuItem value="new">Create New Feed</MenuItem>}
                  {(visibility === 'public' ? publicFeeds : privateFeeds).sort((a, b) => {
                    const numA = parseInt(a.config_id.split('_')[1], 10);
                    const numB = parseInt(b.config_id.split('_')[1], 10);
                    return numB - numA; // Descending order
                  }).map((feed) => (
                    <MenuItem key={feed.config_id} value={feed.config_id}>{`${feed.name} (${feed.config_id})`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
      }
      { visibility === 'public' && !isShowcaseMode &&
        <Grid item xs={12} sm={12} mb={4} sx={{width:'100%'}}>
          <Button sx={{textTransform: 'none', padding: 0, color: primaryColor}}  onClick={() => setIsShowcaseMode(true)}>
            {`< Back to Browse Templates`}
          </Button>
        </Grid>
      }
      <Grid item xs={12} md={isShowcaseMode ? 12 : 6} sm={isShowcaseMode ? 12 : 8} pr={isShowcaseMode ? 0 : 2}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
            <Loader />
          </Box>
        ) : (
          <>
          {/* <PageIntro 
              title="Feed Configurations" 
              intro="Feed configurations makes it possible to change the behaviour of a feed here in mbd console 
              without any code change in the frontend.  To get started, clone a public template as a base, or craft 
              one from scratch.  Once you save a feed, a feed_id is generated, which can be used in API calls in 
              place of the selected params." /> */}

          {
            isShowcaseMode ? <FeedTemplateShowcase 
              publicFeeds={publicFeeds}
              privateFeeds={privateFeeds}
              createNewFeed={createNewFeed}
              selectTemplate={selectTemplate}
              editFeed={editFeed}
              /> 
            : 
            <Box>

              { !isEditMode ? <NonEditableFeedDetails />  :
                  <>
                    <Box pt={1} display="flex" flexDirection={'row'} alignItems={'center'} fullWidth>
                      <Box sx={{flexGrow: 1}}>
                        <Text variant='h1'>Feed Info</Text>
                      </Box>
                      <Stack direction={'row'}>
                        { !isNewFeed &&
                          <IconButton onClick={handleCancelEdit} color="error" sx={{width: 40, padding: 1, fontSize: 'small'}}>
                            <Tooltip title="Cancel editing feed">
                              <UndoIcon />
                            </Tooltip>
                          </IconButton> 
                        }
                        { !isNewFeed &&
                          <IconButton onClick={handleSaveAs} color="error" sx={{width: 40, padding: 1, fontSize: 'small'}}>
                            <Tooltip title="Clone this feed as a new feed">
                              <CopyIcon />
                            </Tooltip>
                          </IconButton> 
                        }
                        { !isNewFeed &&
                          <IconButton onClick={handleDelete} color="error" sx={{width: 40, marginRight: 1}}>
                            <Tooltip title="Delete Feed">
                              <DeleteIcon />
                            </Tooltip>
                          </IconButton>
                        }
                        <PrimaryButton onClick={handleSave}> Save Feed </PrimaryButton>   
                      </Stack>
                    </Box>    
                    <Box display="flex" mt={4} fullWidth>
                    
                      <NonEditableTextField horizontal={true} heading="Feed ID" value={isNewFeed ? '(feed_id generated when saved)' : feedId}/>
                      <Box flexGrow={1}>
                        <Box display="flex" mt={4} fullWidth sx={{alignItems: 'center'}}>
                            <Text variant='fieldHeading' color='brand-light' sx={{minWidth: 80}}>
                              Feed Name
                            </Text>
                            <TextField 
                              fullWidth
                              value={feedName} 
                              onChange={(e) => setFeedName(e.target.value)} 
                              variant="outlined" 
                              sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor} , marginLeft: 2}} 
                            />
                          </Box>        
                      </Box>
                    </Box>
                    <Box display="flex" mt={4}>
                      <SelectField heading="Endpoint" value={endpoint} onChange={handleEndpointChange} options={endpointOptions.map(option => ({label: option, value: option}))} fullWidth/>
                    </Box>
                    <Box mt={4}>
                      <Box  mr={4} mt={4} sx={{display:'flex', flexDirection: 'column', alignItems: 'flex-start'}} fullWidth >
                        <Text variant='fieldHeading' color='brand-light' sx={{minWidth: 80}}>
                          Description
                        </Text>
                        <TextField 
                          fullWidth
                          multiline
                          rows={4}
                          value={description} 
                          onChange={(e) => setDescription(e.target.value)} 
                          variant="outlined" 
                          sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor} , marginTop: 2}} 
                        />
                      </Box>
                    </Box>
                    <Box mt={4}>
                        <Text variant='h1'>Feed Configurations</Text>
                    </Box>
                    <Box display="flex" gap={2} mt={4} fullWidth>
                      {/* <Box flexGrow={1}>
                        <SelectField horizontal heading="Scoring" value={scoring} onChange={handleScoringChange} options={scoringOptions[endpoint].map(option => ({label: option, value: option}))} />
                      </Box> */}
                      <CountField horizontal heading="Impression Count" value={impressionCount} onChagne={(e) => setImpressionCount(e.target.value)}/>
                    </Box>
                    {/* Include Params Section */}
                    <Box display="flex" alignItems="center" gap={2} mt={6}>
                      <Text variant='h2'>Include Params</Text>
                      {
                        isEditMode &&
                        <Button onClick={handleIncludeParamsDialog}>
                          <Tooltip title="Add or remove inclusion rules">
                            <EditIcon sx={{color:primaryColor}}/>
                          </Tooltip>
                        </Button>
                      }
                    </Box>

                    <Dialog open={openIncludeDialog} onClose={() => setOpenIncludeDialog(false)}>
                      <DialogTitle>Select Include Params</DialogTitle>
                      <DialogContent>
                        {['author_ids', 'channels', 'ai_labels', 'embed_domains', 'publication_types'].map((param) => (
                          <FormControlLabel
                            key={param}
                            control={<Checkbox checked={selectedIncludeParams.includes(param)} onChange={handleIncludeParamsChange} name={param} />}
                            label={param}
                            disabled={visibility === 'public'} // Disable in public mode
                          />
                        ))}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setOpenIncludeDialog(false)}>Close</Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog open={openLabelOptionsDialog} onClose={() => setOpenLabelOptionsDialog(false)}>
                      <DialogTitle>Set AI Label Threshold</DialogTitle>
                      <DialogContent>
                        <NonEditableTextField horizontal heading="AI Label" value={currentAiLabel}/>
                        <NonEditableTextField horizontal heading="Threshold" value={aiLabelThresholds[currentAiLabel] || 0}/>
                        {/* <CountField horizontal heading="Threshold" value={aiLabelThresholds[currentAiLabel] || 0} onChagne={(e, value) => setAiLabelThresholds(prev => ({ ...prev, [currentAiLabel]: value }))} step={0.01}/> */}
                        <Box px={2}>
                        <Slider
                          value={currentAiLabelThreshold || 0} // Use the stored threshold value or default to 0
                            onChange={(e, value) => {
                              console.log("changed: ", value); 
                              setCurrentAiLabelThreshold(value); 
                              setAiLabelThresholds(prev => ({ ...prev, [currentAiLabel]: value })) } }
                          step={0.01}
                          min={0}
                          max={1}
                          valueLabelDisplay="auto" // Display value on the slider
                          sx={{ color: '#97979a', mt: 2, minWidth: 400 }}
                        />
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button  sx={{ color: primaryColor }} onClick={() => setOpenLabelOptionsDialog(false)} >OK</Button>
                      </DialogActions>
                    </Dialog>


                    <Box my={2}>
                      {selectedIncludeParams.includes('author_ids') && (
                          <Box mt={4}>
                            <Text variant='fieldHeading' color='brand-light'>
                              Author Ids
                            </Text>
                            <Autocomplete
                              multiple
                              options={authorOptions}
                              value={includeAuthorIds}
                              onInputChange={async (e, newValue) => {
                                if (newValue) {
                                  const options = await fetchAutocompleteOptions(newValue, 'author_ids');
                                  setAuthorOptions(options);
                                }
                              }}
                              onChange={(e, newValue) => setIncludeAuthorIds(newValue)}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                              sx={{ my: 2 }}
                            />
                          </Box>
                      )}
                      {selectedIncludeParams.includes('channels') && (
                          <Box mt={4}>
                            <Text variant='fieldHeading' color='brand-light'>
                              Channels
                            </Text>
                            <Autocomplete
                              multiple
                              options={channelOptions}
                              value={channels}
                              onInputChange={async (e, newValue) => {
                                if (newValue) {
                                  const options = await fetchAutocompleteOptions(newValue, 'channels');
                                  setChannelOptions(options);
                                }
                              }}
                              onChange={(e, newValue) => setChannels(newValue)}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                              sx={{ my: 2 }}
                            />
                          </Box>
                        
                      )}
                      {selectedIncludeParams.includes('ai_labels') && (
                            <>
                                <Text variant="fieldHeading" color='brand-light'>AI Labels</Text>
                                <Autocomplete
                                  multiple
                                  options={aiLabelsOptions}
                                  value={includesAILABELS}
                                  onChange={(e, newValue) => { 
                                    const newLabel = findNewItem(includesAILABELS, newValue); 
                                    console.log('newValue: ', newValue); 
                                    setIncludeAILABELS(newValue);
                                    setCurrentAiLabel(newLabel); 
                                    setCurrentAiLabelThreshold(aiLabelThresholds[newLabel] || 0);
                                    setOpenLabelOptionsDialog(true)
                                  }}
                                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                      const { key, ...tagProps } = getTagProps({ index });
                                      return (
                                        <Chip disabled={(endpoint !== 'casts/feed/trending' && endpoint !== 'casts/feed/popular')} label={option} key={key} {...tagProps} onClick={
                                          (endpoint === 'casts/feed/trending' || endpoint === 'casts/feed/popular') ? 
                                            () => { 
                                          setCurrentAiLabel(option); 
                                          setCurrentAiLabelThreshold(aiLabelThresholds[option] || 0);
                                          setOpenLabelOptionsDialog(true)}
                                          : () => {}} />
                                      );
                                    })
                                  }
                          
                                  sx={{ my: 2 }}
                                />


                            </>
                      )}
                      {selectedIncludeParams.includes('embed_domains') && (

                          <Box mt={4}>
                          <Text variant='fieldHeading' color='brand-light'>
                            Embed Domains
                          </Text>
                          <Autocomplete
                                  multiple
                                  options={embedDomainsOptions}
                                  value={embedDomains}
                                  onChange={(e, newValue) => setEmbedDomains(newValue)}
                                  renderInput={(params) => <TextField {...params} label="Embed Domains" variant="outlined" />}
                                  sx={{ my: 2 }}
                          />
                          </Box>

                      )}
                      {selectedIncludeParams.includes('publication_types') && (
                        <Box mt={4}>
                          <Text variant='fieldHeading' color='brand-light'>
                            Publication Types
                          </Text>
                          <Autocomplete
                              multiple
                              options={publicationTypesOptions.map(option => ({label: option, value: option}))}
                              value={includePublicationTypes}
                              onChange={(e, newValue) => setIncludePublicationTypes(newValue)}
                              renderInput={(params) => <TextField {...params} label="Publication Types" variant="outlined" />}
                              sx={{ my: 2 }}
                            />
                          </Box>
                      )}
                    </Box>

                    {/* Exclude Params Section */}
                    <Box display="flex" alignItems="center" gap={2} mt={4}>
                      <Text variant='h2'>Exclude Params</Text>
                      {
                        isEditMode &&
                        <Button onClick={handleExcludeParamsDialog}>
                          <Tooltip title="Add or remove exclusion rules">
                            <EditIcon sx={{color:primaryColor}}/>
                          </Tooltip>
                        </Button> 
                      }
                    </Box>

                    <Dialog open={openExcludeDialog} onClose={() => setOpenExcludeDialog(false)}>
                      <DialogTitle>Select Exclude Params</DialogTitle>
                      <DialogContent>
                        {['author_ids', 'ai_labels'].map((param) => (
                          <FormControlLabel
                            key={param}
                            control={<Checkbox checked={selectedExcludeParams.includes(param)} onChange={handleExcludeParamsChange} name={param} />}
                            label={param}
                            disabled={visibility === 'public'} // Disable in public mode
                          />
                        ))}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setOpenExcludeDialog(false)}>Close</Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog open={openExcludeLabelOptionsDialog} onClose={() => setOpenExcludeLabelOptionsDialog(false)}>
                      <DialogTitle>Set AI Label Threshold</DialogTitle>
                      <DialogContent>
                        <NonEditableTextField horizontal heading="AI Label" value={currentAiLabel}/>
                        <NonEditableTextField horizontal heading="Threshold" value={removeAILabelThresholds[currentAiLabel] || 0}/>
                        <Box px={2}>
                        <Slider
                          value={currentAiLabelThreshold || 0} // Use the stored threshold value or default to 0
                            onChange={(e, value) => {
                              console.log("changed: ", value); 
                              console.log("removeAILabelThresholds: ", removeAILabelThresholds); 
                              setCurrentAiLabelThreshold(value); 
                              setRemoveAILabelThresholds(prev => ({ ...prev, [currentAiLabel]: value })) } }
                          step={0.01}
                          min={0}
                          max={1}
                          valueLabelDisplay="auto" // Display value on the slider
                          sx={{ color: '#97979a', mt: 2, minWidth: 400 }}
                        />
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button  sx={{ color: primaryColor }} onClick={() => setOpenExcludeLabelOptionsDialog(false)} >OK</Button>
                      </DialogActions>
                    </Dialog>

                    <Box my={2}>
                      {selectedExcludeParams.includes('author_ids') && (
                        <Box mt={4}>
                            <Text variant='fieldHeading' color='brand-light'>
                              Exclude Author Ids
                            </Text>
                            <Autocomplete
                              multiple
                              options={authorOptions}
                              value={excludeAuthorIds}
                              onInputChange={async (e, newValue) => {
                                if (newValue) {
                                  const options = await fetchAutocompleteOptions(newValue, 'author_ids');
                                  setAuthorOptions(options);
                                }
                              }}
                              onChange={(e, newValue) => setExcludeAuthorIds(newValue)}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                              sx={{ my: 2 }}
                            />
                        </Box>

                      )}
                      {selectedExcludeParams.includes('ai_labels') && (
                          <>
                                <Text variant="fieldHeading" color='brand-light'>AI Labels</Text>
                                <Autocomplete
                                  multiple
                                  options={aiLabelsOptions}
                                  value={excludeAILABELS}
                                  onChange={(e, newValue) => { 
                                    const newLabel = findNewItem(includesAILABELS, newValue); 
                                    console.log('newValue: ', newValue); 
                                    setExcludeAILABELS(newValue);
                                    setCurrentAiLabel(newLabel); 
                                    setCurrentAiLabelThreshold(aiLabelThresholds[newLabel] || 0);
                                    setOpenExcludeLabelOptionsDialog(true)
                                  }}
                                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                      const { key, ...tagProps } = getTagProps({ index });
                                      return (
                                        <Chip disabled={(endpoint !== 'casts/feed/trending' && endpoint !== 'casts/feed/popular')} label={option} key={key} {...tagProps} onClick={
                                          (endpoint === 'casts/feed/trending' || endpoint === 'casts/feed/popular') ? 
                                            () => { 
                                          setCurrentAiLabel(option); 
                                          setCurrentAiLabelThreshold(removeAILabelThresholds[option] || 0);
                                          setOpenExcludeLabelOptionsDialog(true)}
                                          : () => {}} />
                                      );
                                    })
                                  }
                          
                                  sx={{ my: 2 }}
                                />

                          </>
                      )}
                    </Box>
                    {/* Promotion Section */}
                    <Box display="flex" alignItems="center" gap={2} mt={4}>
                      
                      <Text variant='h2'>Promotion</Text>
                      {
                        isEditMode &&
                        <Button onClick={() => {
                          setShowPromotionSection(!showPromotionSection);
                          if (!showPromotionSection) {
                              // Reset promotion fields when closing
                              setPromotionName('');
                              setSelectedPromotionFeedId('');
                              setSelectedPromotionFeedName('');
                              setPromotionPercentage(0);
                              setItemsList([{ item_id: '', rank: '' }]);
                          }
                          }}>
                          <Tooltip title="Add or remove exclusion rules">
                            <EditIcon sx={{color:primaryColor}}/>
                          </Tooltip>
                        </Button>
                      }
                    </Box>

                    {showPromotionSection && (
                      <Box gap={2} my={2}>
                        <SelectField heading="Promotion Type" value={promotionTypeValue} onChange={(e) => setPromotionTypeValue(e.target.value)} options={promotionTypeList} fullWidth/>
                        <Box mt={4} sx={{display:'flex', flexDirection: 'column', alignItems: 'flex-start'}} fullWidth >
                              <Text variant='fieldHeading' color='brand-light' sx={{minWidth: 80}}>
                                Promotion Name
                              </Text>
                              <TextField 
                                fullWidth
                                value={promotionName} 
                                onChange={(e) => setPromotionName(e.target.value)} 
                                variant="outlined" 
                                sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor} , marginTop : 2}} 
                              />
                            </Box>
                        {/* Render fields for "feed" promotion type */}
                        {promotionTypeValue === 'feed' && (
                          <>
                            <Box mt={2}>
                              <SelectField heading="Select Feed for Promotion" value={selectedPromotionFeedId} onChange={(e) => {
                                    const feedId = e.target.value;
                                    setSelectedPromotionFeedId(feedId);
                                    const feed = [...publicFeeds, ...privateFeeds].find((f) => f.config_id === feedId);
                                    setSelectedPromotionFeedName(feed ? feed.name : '');
                                  }}
                                  options={publicFeeds.concat(privateFeeds).filter(
                                    (f) => f.endpoint === endpoint && f.config_id !== feedId
                                  ).map(
                                    (feed) => ({label: `${feed.name} (${feed.config_id})`, value: feed.config_id})
                                  )}/>
                            </Box>

                            <Box display="flex" gap={2} my={2}>
                              <NonEditableTextField horizontal={true} heading="Feed ID" value={selectedPromotionFeedId}/>
                              <NonEditableTextField horizontal={true} heading="Feed Name" value={selectedPromotionFeedName}/>
                            </Box>

                            <Box mt={4} mb={4}>
                              <Text variant='fieldHeading' color='brand-light'>Percentage</Text>
                              <Box mt={2} px={2}>
                              <Slider
                                value={promotionPercentage}
                                onChange={(e, value) => setPromotionPercentage(value)}
                                step={1}
                                min={0}
                                max={100}
                                valueLabelDisplay="auto"
                                sx={{ color: '#97979a' }}
                              />
                              </Box>
                            </Box>
                          </>
                        )}

                        {/* Render fields for "items" promotion type */}
                        {promotionTypeValue === 'items' && (
                          <>
                            {itemsList.map((item, index) => (
                              <Box display="flex" gap={2} my={2} key={index} alignItems={'center'} alignContent={'center'}>
                                <Box flexGrow={1}>
                                  <Box sx={{display:'flex', flexDirection: 'row', alignItems:'center' }} >
                                    <Text variant='fieldHeading' color='brand-light'>
                                      Item ID
                                    </Text>
                                    <TextField 
                                      fullWidth
                                      value={item.item_id} 
                                      onChange={(e) => handleItemChange(e.target.value, index, 'item_id')} 
                                      variant="outlined" 
                                      sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor} , marginLeft: 1}} 
                                    />

                                  </Box>                                
                                </Box>
                                <Box>
                                  <Box sx={{display:'flex', flexDirection: 'row', alignItems:'center' }} >
                                    <Text variant='fieldHeading' color='brand-light'>
                                      Rank
                                    </Text>
                                    <TextField 
                                      type="number"
                                      step={1}
                                      value={item.rank} 
                                      onChange={(e) => handleItemChange(e.target.value, index, 'rank')} 
                                      variant="outlined" 
                                      sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor} , marginLeft: 1}} 
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: 'center' } // Center align the input text
                                      }}
                                    />
                                  </Box>                                
                                </Box>

                                <Box sx={{height: '100%', alignItems: 'center'}} py={2}>
                                  <IconButton mt={2} onClick={() => removeItem(index)} color="error" sx={{width: 40, marginRight: 1}}>
                                      <RemoveIcon />
                                  </IconButton>
                                </Box>

                              </Box>
                            ))}
                            <Box my={4}>
                              <PrimaryButton onClick={addItem}> + Add Another Item </PrimaryButton> 
                            </Box>
                          </>
                        )}
                      </Box>
                    )}
                  </>
              }

              {/* <Box display="flex" gap={2}>

                {!isMobile && !isPreviewOpen &&
                <Button
                  onClick={togglePreviewDrawer}
                  // startIcon={<PreviewIcon stye={{color: 'white'}}/>}
                  sx={{ position: 'fixed', width: 60, height: 60, borderRadius: '50%', right:40, bottom: 40,  backgroundColor: primaryColor, '&:hover': { backgroundColor: '#ed9ed2' }}}
                  
                >
                  <PreviewIcon sx={{color: 'white'}}/>
                </Button>}
              </Box> */}

              <Snackbar open={showSnackbar} autoHideDuration={9000} onClose={() => setShowSnackbar(false)}>
                <Alert onClose={() => setShowSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                  {snackbarMessage}
                </Alert>
              </Snackbar>

            {/* {isMobile ? (
              <Box
                sx={{
                  width: '100%',
                  mt: 3, 
                  position: 'relative', 
                  bottom: 0,
                  display: feedId ? 'block' : 'none', 
                }}
              >
                <Typography variant="h6">Feed Preview</Typography>
                <Box
                  sx={{
                    mt: 2,
                    overflowY: 'auto',
                    height: '80vh', 
                  }}
                >
                  {feedId ? (
                    <iframe
                      src={`${playgroundUrl}/Feed_Config?feed-id=${feedId}`}
                      width="100%" // Full width
                      height="100%" // Full height within the container
                      style={{ border: 'none' }}
                      title="Feed Preview"
                    ></iframe>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Please select a feed.
                    </Typography>
                  )}
                </Box>
              </Box>
            ) : (
              // Desktop view: render the drawer as usual
              <Drawer
                anchor="right"
                open={isPreviewOpen}
                onClose={togglePreviewDrawer}
                PaperProps={{
                  sx: {
                    width: '600px',
                    height: '100%',
                    overflow: 'hidden',
                  },
                }}
              >
                <Box p={2} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box display="flex" flexDirection={'row'}>
                    <Typography variant="h6" flexGrow={1} >Preview Feed</Typography>
                    <IconButton onClick={() => setIsPreviewOpen(false)}>
                      <CloseIcon />
                    </IconButton> 

                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      overflowY: 'auto',
                      mt: 2,
                    }}
                  >
                    {feedId ? (
                      <iframe
                        src={`${playgroundUrl}/Feed_Config?feed-id=${feedId}`}
                        width="100%"
                        height="100%"
                        style={{ border: 'none', minHeight: 'calc(100vh - 120px)' }}
                        title="Feed Preview"
                      ></iframe>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        Please select a feed.
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Drawer>
            )} */}

            </Box>
          }

        </>
      )}
    </Grid>
    { !isShowcaseMode &&
    <Grid item xs={12} md={6} sm={4} sx={{borderLeftWidth: 1, borderLeftColor: '#e0e0e0'}}>
      <Box mt={1}>
        <Text variant='h1'>Sample Code</Text>
        <Text variant='body' color='gray-light' sx={{mt:2}}>Example usage of feed_id in the APIs:</Text>
        <SyntaxHighlighter language="javascript" style={solarizedDarkAtom} showLineNumbers customStyle={customStyle}>
                {`// API request body:
{
  // use your feed id like this below
  "feed_id": '${feedId}',

  //other parameters for the request that 
  // you can use
  "user_id": "1",
  "return_metadata": true,
  "top_k": 10

   // additonal parameters to override the 
   // feed configurations saved in this feed_id

 }`}
          </SyntaxHighlighter>
        </Box>
        <>
        {isMobile ? (
              <Box
                sx={{
                  width: '100%',
                  mt: 3, 
                  position: 'relative', 
                  bottom: 0,
                  display: feedId ? 'block' : 'none', 
                }}
              >
                <Typography variant="h6">Feed Preview</Typography>
                <Box
                  sx={{
                    mt: 2,
                    overflowY: 'auto',
                    height: '80vh', 
                  }}
                >
                  {feedId ? (
                    <iframe
                      key={iframeKey}
                      src={`${playgroundUrl}/Feed_Config?feed-id=${feedId}`}
                      width="100%" // Full width
                      height="100%" // Full height within the container
                      style={{ border: 'none' }}
                      title="Feed Preview"
                    ></iframe>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Please select a feed.
                    </Typography>
                  )}
                </Box>
              </Box>
            ) : (
              // Desktop view: render the drawer as usual
              // <Drawer
              //   anchor="right"
              //   open={isPreviewOpen}
              //   onClose={togglePreviewDrawer}
              //   PaperProps={{
              //     sx: {
              //       width: '600px',
              //       height: '100%',
              //       overflow: 'hidden',
              //     },
              //   }}
              // >
                <Box p={0} sx={{ height: '70%', width: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
                  <Box display="flex" flexDirection={'row'}>
                    <Typography variant="h6" flexGrow={1} >Feed Preview</Typography>
                    {/* <IconButton onClick={() => setIsPreviewOpen(false)}>
                      <CloseIcon />
                    </IconButton>  */}

                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      overflowY: 'auto',
                      mt: 2,
                    }}
                  >
                    {feedId ? (
                      <iframe
                        key={iframeKey}
                        src={`${playgroundUrl}/Feed_Config?feed-id=${feedId}`}
                        width="100%"
                        height="100%"
                        style={{ border: 'none', minHeight: 'calc(100vh - 120px)' }}
                        title="Feed Preview"
                      ></iframe>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        Please select a feed.
                      </Typography>
                    )}
                  </Box>
                </Box>
              // </Drawer>
            )}
        
        </>

      </Grid>    
    }

    </Grid>
  );
}
