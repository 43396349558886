import React from 'react';
import { Animate, Card, Table, Chart, useAPI, Stat, Grid } from 'components/lib';

export function Usage(props){

    const usageChart = useAPI(
      '/api/dashboard/usage','get',{ isPaid: props?.isPaid || 'false' },{} 
    );
    const upcomingInvoice = useAPI(
      '/api/dashboard/upcoming_invoice','get',{ isPaid: props?.isPaid || 'false' },{}
    );
   

    
    // props.base =  'relative p-6 x-1 mb-6 bg-white rounded last-of-type:mb-0 bg-gray-200';
    // props.header =  'relative pb-5 mb-5 border-dotted border-slate-100 border-b bg-gray-200';
    // props.title = 'float:left m-0 text-lg font-semibold bg-gray-200';
    // props.center = 'mx-auto bg-gray-200';
    // props.loading = 'h-60 bg-gray-200';
    // props.shadow = 'drop-shadow-sm bg-gray-200';
    // props.noPadding = '!p-0 bg-gray-200';
    // props.restrictWidth = 'max-w-md bg-gray-200';
    // props.last = 'mb-0 bg-gray-200';
    
  
  return (
    <Card className={ props.className }>
       
        <Animate type='pop'>
        {
        <div>
            <Grid cols='3'>
            <Stat
              loading={ usageChart?.loading }
              value={ usageChart?.data?.extras?.usage }
              label= {`${props.isPaid ? 'Paid' : 'Free'} Plan Total Usage`}
              icon='refresh-cw'
            />
            <Stat
              loading={ upcomingInvoice?.loading }
              value={ upcomingInvoice?.data?.amount }
              label={`${props.isPaid ? 'Paid' : 'Free'} Plan Upcoming Invoice`}
              icon='credit-card'
            />
             <Stat
                loading={ usageChart?.loading }
                value={ usageChart?.data?.extras?.left_usage }
                label= {`${props.isPaid ? 'Paid' : 'Free'} Plan Quota`}
                icon='refresh-cw'
              />
            </Grid>
      
            <Card name='usages' title={`${props.isPaid ? 'Paid' : 'Free'} Plan Usage Per Day`}>
            <Chart
              type='line'
              legend
              loading={ usageChart.loading }
              data={ usageChart.data }
              color={['blue', 'red']}
            />
            </Card>
          
      </div>
      }

        </Animate>

    </Card>
  );
}