import { Feed } from '@mui/icons-material';
import { Dashboard } from 'views/dashboard/dashboard';
import { Help } from 'views/dashboard/help';
import { OnboardingView } from 'views/onboarding/onboarding';
import { Playground } from 'views/playground/playground';
import { FeedConfig } from 'views/feed-config/feed_config';
import { Moderation } from 'views/moderation/moderation';
import { ColdStartFeed } from 'views/cold-start-feed/cold_start_feed';  

const Routes = [
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard'
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome'
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help'
  },
  {
    path: '/playground',
    view: Playground,
    layout: 'app',
    permission: 'user',
    title: 'Playground'
  },
  {
    path: '/feed-builder',
    view: FeedConfig,
    layout: 'app',
    permission: 'user',
    title: 'Feed Builder'
  },
  {
    path: '/moderation',
    view: Moderation,
    layout: 'app',
    permission: 'user',
    title: 'Moderation'
  },
  {
    path: '/cold-start-feed',
    view: ColdStartFeed,
    layout: 'app',
    permission: 'user',
    title: 'Cold Start Feed'
  }
];

export default Routes;
