import React, { useContext, useState, useEffect } from 'react';
import { AuthContext, Animate, Row, Card, Form, useNavigate, useLocation } from 'components/lib';

export function SigninOTP(props){

  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(AuthContext);

  // const token = location?.search?.substring(7);
  const queryParams = new URLSearchParams(location?.search);
  const login_to = queryParams.get('login_to') || null;
  const token = queryParams.get('token') || null;
  if (!token) navigate('/signin');

  const [form, setForm] = useState(
    {
      code: {
        type: 'text',
        label: 'Verification code',
        required: true
      },
      jwt: {
        type: 'hidden',
        value: token,
      }, 
      login_to: {
        type: 'hidden',
        value: null
      }
    }
  );

  useEffect(() => {
    
    const f = {...form };
    f.login_to.value = login_to;
    setForm(f);

  }, [location.search]);

  return (
    <Animate type='pop'>
      <Row title='Enter Verification Code' desc='Please enter the the verification code from your authenticator app'>

        <Card restrictWidth center>
          <Form 
            method='post'
            url='/api/auth/otp'
            inputs={ form }
            buttonText='Complete Sign In'
            callback={ res => {
              (login_to === 'readme') ? window.location.href = res.data.readMeAuthUrl : context.signin(res)
            }}
          />

          <div className='mt-4'>
            Lost access to your authenticator app? Please enter your backup code above.
          </div>

        </Card>

      </Row>
    </Animate>
  )
}