import React from 'react';

export function StreamlitIframe(props) {
    return (
        <iframe 
            src= { props.src}
            width= {props.width}
            height= {props.height}
            title= {props.title}
        />
    );
}
