exports.apiKeyNames = {
    DEFAULT: 'test-docs'
};

exports.plan = {
    FREE: 'free'
};

exports.login_to = {
    README: 'readme'
}
